import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import {
  Formik,
  Form as FormikForm,
  Field as FormikField,
} from 'formik';
import {
  COMPANY_REPROCESS_KPI,
} from 'graphql/companies';

const ReprocessKPIForm = ({ company }) => {
  const [reprocessKPI] = useMutation(COMPANY_REPROCESS_KPI);
  const then = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const now = moment().format('YYYY-MM-DD');
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        from: then,
        to: now,
      }}
      onSubmit={(values, { setSubmitting, setStatus } ) => {
        setStatus(null);
        setSubmitting(true);

        reprocessKPI({
          variables: {
            companyID: company.id,
            from: moment(values.from).startOf('day').format(),
            to: moment(values.to).endOf('day').format()
          }
        })
          .then(response => setStatus({ result: {...response.data.reprocessKpi} }))
          .catch(err => setStatus({ error: err.message }))
          .finally(() => setSubmitting(false));
      }}
    >
      {({ values, setValues, handleSubmit, errors, status, isSubmitting, ...rest }) => (
        <>
          <Modal.Body>
            <Modal.Title>Reprocess GPS coordinates (KPI Engine)</Modal.Title>
            <hr />
            <p>Select a date range to reprocess all GPS coordinates received from operators logging assignments for this company during the selected period.</p>
            <p>All sites will be used when geo-fencing the GPS coordinates, including any recently added sites.</p>
            <p><strong>The results will be reflected in client-facing Looker dashboards.</strong></p>
            <FormikForm>
              <Form.Group>
                <Form.Label>From (start of day)</Form.Label>
                <Form.Control
                  as={FormikField}
                  type="date"
                  id="from"
                  name="from"
                  isInvalid={!!errors.name}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>To (end of day)</Form.Label>
                <Form.Control
                  as={FormikField}
                  type="date"
                  id="to"
                  name="to"
                  isInvalid={!!errors.name}
                />
              </Form.Group>
            </FormikForm>
            {!!status && !!status.result && <strong>Reprocessed {status.result.num_reprocessed} metrics in {status.result.seconds} seconds.</strong>}
            {!!status && !!status.error && <Alert variant="danger">{status.error}</Alert>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={{"spinner": isSubmitting}}
              variant="primary"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Reprocessing...' : 'Reprocess'}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  )
};

export default ReprocessKPIForm;
