import React, { useState } from 'react';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSMSLogs } from 'graphql/common';
import styles from './styles.module.scss';

// eslint-disable-next-line
type SMSLog = {
  dateCreated: string,
  dateSent: string,
  to: string,
  from: string,
  direction: string,
  body: string,
  status: string,
  errorMessage: string | null,
  errorCode: number | null,
};

const SMSLogRow = ({ smsLog }) => {
  return (
    <tr>
      <td>{moment(smsLog.dateCreated).format('MMM D, YYYY hh:mm:ss ZZ')}</td>
      <td>{smsLog.direction === 'inbound' ? 'From' : 'To'}</td>
      <td>{smsLog.to}</td>
      <td>{smsLog.body}</td>
      <td>{smsLog.status}</td>
      <td>{!!smsLog.errorMessage ? smsLog.errorMessage : '-'}</td>
    </tr>
  );
};

const smsLogLimits = [20, 50, 100, 250];

const SMSLogView = ({
  accountID,
  logLimit,
  setLogLimit,
}) => {
  const [smsLogs, smsLoading, refetch] = useSMSLogs(accountID, logLimit);
  return (
    <>
      <div className={styles.settingsHeader}>
        <h5>SMS Logs</h5>
        <Button
          className="ml-2 mr-2"
          disabled={smsLoading}
          onClick={refetch}
          size="sm"
          variant="outline-success"
        >
          {smsLoading ? '...' : 'Refresh'}
        </Button>
        <select
          id="logLimitSelect"
          name="logLimit"
          onChange={(e) => setLogLimit(e.target.value)}
        >
          {smsLogLimits.map(l => (
            <option
              key={`limit-${l}`}
              value={l}
            >
              {l}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.smsLogTable}>
        <Table size="sm" striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Direction</th>
              <th>To/From</th>
              <th>Body</th>
              <th>Status</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            {smsLoading && <tr><td colSpan="5">Loading...</td></tr>}
            {!smsLoading && smsLogs.length > 0 &&
                smsLogs.map((m, i) => (
                  <SMSLogRow key={`${accountID}-smslog-${i}`} smsLog={m} />
                ))
            }
            {!smsLoading && smsLogs.length <= 0 && <tr><td colSpan="5">No SMS logs found</td></tr>}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const SMSLogTable = ({
  accountID,
  action,
}) => {
  const [logLimit, setLogLimit] = useState(20);
  if (action !== 'update') {
    return null;
  }
  return (
    <Modal.Body>
      <SMSLogView accountID={accountID} logLimit={logLimit} setLogLimit={setLogLimit} />
    </Modal.Body>
  );
};

export default SMSLogTable;
