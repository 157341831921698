import React, { useState, useEffect } from 'react';
//import logo from './logo.svg';
import axios from 'axios';
import classnames from 'classnames';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import './App.css';

import { APIAUTHURL, APIDOMAIN, APIBASEURL } from 'utils/env';
import { MainApp } from './MainApp';
import { useWriteError, useWhoAmI, LOCAL_ERRORS_QUERY } from 'graphql/common';
import {
  apolloClient,
} from 'graphql/client';


export const post = (url, data) => (
  axios.post(url, data, {
    baseURL: APIAUTHURL,
    withCredentials: true,
  })
);

export const logoutCSTool = () => {
  axios.post('token/clear', {}, {
    baseURL: APIBASEURL,
    withCredentials: true,
  })
  // Clear CS Tool cookie manually
  document.cookie = `token=;Domain=${APIDOMAIN};Expires=${moment().subtract(1, 'months').toDate().toUTCString()}`;
};

export const get = url => (
  axios.get(url, {
    baseURL: APIAUTHURL,
  })
);

const setCookie = (token, domain) => {
  const expiry = moment().add(1, 'months').toDate().toUTCString();
  document.cookie = `token=${token};Domain=${domain};Expires=${expiry};SameSite=Lax`;
};

// We need to set the cookie to our own CS Tool backend domain
const auth = (phoneNumber, setPage, setSubmitting, writeError) => {
  const params = new URLSearchParams();
  params.append('phone_number', `+1${phoneNumber}`);
  params.append('source', 'cs_tool');
  post('token/code', params)
    .then((res) => {
      // Unmarshal JSON for token
      const token = res.data.token;
      if (token === "") {
        throw new Error("Failed to get returned token from core!");
      }
      setCookie(token, APIDOMAIN);
      setPage('verify');
    })
    .catch((err) => {
      setSubmitting(false);
      if (err.response) {
        writeError(`Server encountered an error! ${err.response.statusText}`);
      } else {
        writeError(`Server encountered an unknown error!`);
      }
    });
};

const verify = (code, setPage, setSubmitting, history, writeError) => {
  const params = new URLSearchParams();
  // NOTE Golang considers cookies to be RAW, while this universal-cookies lib
  // will URLencode it!! Meaning the version string tacked at the end (:xxxxxxxx)
  // becomes %3Axxxxxxxx!
  /*
  cookies.set('token', token, {
    path: '/',
    domain: APIDOMAIN,
  });
  */
  params.append('code', code);
  post('token/auth', params)
    .then((res) => {
      const token = res.data.token;
      if (token === "") {
        throw new Error("Failed to get returned token from core!");
      }
      setCookie(token, APIDOMAIN);
      history.push("/impersonate")
    })
    .catch((err) => {
      setSubmitting(false);
      if (err.response) {
        writeError(`Server encountered an error! ${err.response.statusText}`);
      } else {
        writeError('Server encountered an unknown error!');
      }
      setPage('request');
    });
};

const logout = (history) => {
  logoutCSTool();
  post('token/clear')
    .then(() => {
      apolloClient.resetStore();
      history.push("/");
    });
}

export const CenterContainer = ({ children }) => (
  <Container className="h-100">
    <Row className={classnames('h-100', 'align-items-center')}>
      <Col className="justify-content-center">
        {children}
      </Col>
    </Row>
  </Container>
);

const PhoneNumber = ({ setPage, handleSubmit }) => {
  const { data, error, loading } = useQuery(LOCAL_ERRORS_QUERY);
  return (
    <div>
      <CenterContainer>
        { !loading && !error && !!data && !!data.error && !!data.error.errorMessage &&
            <Alert variant="danger">{data.error.errorMessage}</Alert>
        }
        <Card>
          <Card.Header>Tread Admin Login</Card.Header>
          <Card.Body>
            <FormikForm>
              <Form.Group>
                <Form.Control
                  as={FormikField}
                  autoFocus
                  id="phoneNumberInput"
                  placeholder="Phone Number"
                  name="phoneNumber"
                />
              </Form.Group>
              <Button onClick={handleSubmit}>Submit</Button>
            </FormikForm>
          </Card.Body>
        </Card>
      </CenterContainer>
    </div>
  )
};

const Verify = ({ setPage, handleSubmit }) => {
  return (
    <div>
      <CenterContainer>
        <Card>
          <Card.Header>Tread Admin Login</Card.Header>
          <Card.Body>
            <FormikForm>
              <Form.Group>
                <Form.Control
                  as={FormikField}
                  autoFocus
                  id="verifyCodeInput"
                  placeholder="Verification Code"
                  name="verifyCode"
                />
              </Form.Group>
              <Button onClick={handleSubmit}>Submit</Button>
            </FormikForm>
          </Card.Body>
        </Card>
      </CenterContainer>
    </div>
  );
}

const Login = ({ page, setPage, handleSubmit }) => {
  return (
    page === 'request'
      ? <PhoneNumber {...{setPage, handleSubmit }} />
      : <Verify {...{setPage, handleSubmit }} />
  );
};

const LoginPage = (props) => {
  const [page, setPage] = useState('request');
  const writeError = useWriteError();
  const history = useHistory();
  const [whoami, error] = useWhoAmI();

  useEffect(() => {
    if (!error && whoami && whoami.id > 0 && whoami.globalAdmin) {
      history.push('/impersonate')
    }
  }, [whoami, error, history]);

  return (
    <>
      <Formik 
        initialValues={{
          phoneNumber: '',
          verifyCode: '',
        }}
        onSubmit={({ phoneNumber, verifyCode }, { setSubmitting }) => {
          setSubmitting(true);
          if (page === 'request') {
            auth(phoneNumber, setPage, setSubmitting, writeError);
          } else if (page === 'verify') {
            verify(verifyCode, setPage, setSubmitting, history, writeError);
          }
        }}
        render={({ handleSubmit }) => (
          <Login {...{page, setPage, handleSubmit}} />
        )}
      />
    </>
  );
};

const LogoutRedirect = (props) => {
  logout(props.history);
  return (
    <div>Logging out...</div>
  );
}

function App() {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/logout" component={LogoutRedirect} />
      <Route component={MainApp} />
    </Switch>
  );
}

export default App;
