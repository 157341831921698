import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AddExistingSubcontractorForm from './AddExistingSubcontractorForm';
import { CompanySelect } from 'components/common';
import { permissions } from './ClientList';

export const SubcontractorRow = ({
  id,
  subcontractorCompany,
  available,
  operatorCount,
  clientCompany,
  toggleSelect,
}) => {
  const findKeyFromValue = (obj, val) => Object.keys(obj).find(key => obj[key] === val);
  const permissionVal = clientCompany.permissions.hotDispatch ? findKeyFromValue(permissions, clientCompany.permissions.hotDispatch.permission) : '';
  return (
    <tr
    >
      <td>
      <Form.Check
          onChange={() => toggleSelect(subcontractorCompany.id)}
        />
      </td>
      <td>{subcontractorCompany.id}</td>
      <td>{subcontractorCompany.name}</td>
      <td className={subcontractorCompany.status === 'ACTIVE' ? 'text-success' : 'text-danger'}>{subcontractorCompany.status}</td>
      <td className={available ? 'text-success' : 'text-danger'}>{available ? 'Available' : 'Not Available'}</td>
      <td>{operatorCount}</td>
      <td>{permissionVal}</td>
    </tr>
  );
};

export const SubcontractorsTable = ({
  subcontractors,
  toggleSelect,
}) => (
  <Table striped bordered hover>
    <thead>
      <tr>
        <th/>
        <th>Company ID</th>
        <th>Name</th>
        <th>Status</th>
        <th>Available</th>
        <th>Operator Count</th>
        <th>Hire Access</th>
      </tr>
    </thead>
    <tbody>
      {!!subcontractors &&
          subcontractors.map(s => (
            <SubcontractorRow
              key={s.companySubcontractor.subcontractor.id}
              id={s.id}
              subcontractorCompany={s.companySubcontractor.subcontractor}
              clientCompany={s.companyClient}
              operatorCount={s.companySubcontractor.operatorCount}
              available={s.companySubcontractor.available}
              toggleSelect={toggleSelect}
            />
          ))
      }
    </tbody>
  </Table>
);

export const SubcontractorList = ({
  companies,
  subcontractorClients,
  companyID,
  setCompanyID,
  setFileUploadVisible,
  setCompanyDetailsVisible,
  setConfirmCardVisible,
  setAddClientFormVisible,
  selectedIDs,
  toggleSelect,
  setStatus,
}) => {
  const [addExistingVisible, setAddExistingFormVisible] = useState(false);
  return (
    <>
      <CompanySelect companies={companies} setCompanyID={setCompanyID}/>
      <ButtonGroup className="mb-2">
        <Button
          className="mr-2"
          variant="info"
          onClick={() => setAddExistingFormVisible(true)}
        >
          Add EXISTING Company as Subcontractor
        </Button>
        <Button
          className="mr-2"
          variant="success"
          onClick={() => {
            setFileUploadVisible(true);
          }}
        >
          Bulk Add NEW Subcontractors-Clients
        </Button>
        <Button
          className="mr-2"
          variant="danger"  disabled={selectedIDs.length === 0}
          onClick={() => {
            setConfirmCardVisible(true);
          }}
        >
          Remove Selected
        </Button>
      </ButtonGroup>

      <AddExistingSubcontractorForm
        visible={addExistingVisible}
        setVisible={setAddExistingFormVisible}
        companyID={companyID}
        companies={companies}
        setStatus={setStatus}
      />
      <div/>
      Subcontractors
      <SubcontractorsTable
        subcontractors={subcontractorClients}
        toggleSelect={toggleSelect}
      />
    </>
  );
};
