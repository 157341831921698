import { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const WHOAMI_QUERY = gql`
  query whoAmI {
    whoami {
      id
      firstName
      lastName
      globalAdmin
    }
  }
`;

export const useWhoAmI = () => {
  const { data, loading, error } = useQuery(WHOAMI_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 30*1000,
  });
  return useMemo(() => (
    (!loading && !error)
      ? [data.whoami, null]
      : [null, error]
  ), [data, loading, error]);
}

export const LOCAL_ERRORS_QUERY = gql`
  query {
    error @client {
      __typename
      errorMessage
    }
  }
`;

const LOCAL_ERROR_MUTATION = gql`
  mutation setError($errorMessage: String) {
    setError(errorMessage: $errorMessage) @client
  }
`;

export const useWriteError = () => {
  const [writeError] = useMutation(LOCAL_ERROR_MUTATION);
  return useCallback((errorMessage) => {
    if (errorMessage && errorMessage.message) {
      writeError({ variables: { errorMessage: errorMessage.message } });
    } else {
      writeError({ variables: { errorMessage } });
    }
  }, [writeError]);
};

const LOCAL_LOGGEDINDETAILS_MUTATION = gql`
  mutation setLoggedInDetails($details: Account) {
    setLoggedInDetails(details: $details) @client
  }
`;

export const useWriteLoggedInDetails = () => {
  const [writeLoggedInDetails] = useMutation(LOCAL_LOGGEDINDETAILS_MUTATION);
  return useCallback((details) => {
    writeLoggedInDetails({ variables: { details } });
  }, [writeLoggedInDetails]);
}

export const LOCAL_LOGGEDINDETAILS_QUERY = gql`
  query {
    loggedInDetails @client {
      __typename
      id
      firstName
      lastName
    }
  }
`;

export const COMPANIES_QUERY = gql`
  query Companies($onlyActive: Boolean = true) {
    companies(onlyActive: $onlyActive) {
      id
      name
      status
    }
  }
`;

export const useCompanies = (onlyActive) => {
  const { data, loading, error } = useQuery(COMPANIES_QUERY, {
    variables: { onlyActive },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companies
      : []
  ), [data, loading, error]);
};

const ACCOUNTS_BY_COMPANY_ID_QUERY = gql`
  query CompanyAccounts($id: ID!) {
    companyAccounts(companyId: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      disabled
    }
  }
`;

export const useAccountsByCompany = (companyID) => {
  const { data, loading, error } = useQuery(ACCOUNTS_BY_COMPANY_ID_QUERY, {
    variables: { id: companyID },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyAccounts
      : []
  ), [data, loading, error]);
};

const SMS_LOGS_BY_ACCOUNT_ID = gql`
  query smsLogsByAccountID($accountID: ID!, $limit: Int!) {
    smsLogs(accountID: $accountID, limit: $limit) {
      dateCreated
      dateSent
      to
      from
      direction
      body
      status
      errorMessage
      errorCode
    }
  }
`;

export const useSMSLogs = (accountID = null, limit = 20) => {
  const { data, loading, error, refetch } = useQuery(SMS_LOGS_BY_ACCOUNT_ID, {
    variables: { accountID, limit },
  });
  const debouncedRefetch = _.debounce(refetch, 500);
  return useMemo(() => {
    if (loading) return [[], loading, debouncedRefetch];
    if (!loading && !error) return [data.smsLogs, loading, debouncedRefetch]
    return [[], false, debouncedRefetch]
  }, [data, loading, error, debouncedRefetch])
}

const ALL_PERMISSIONS_QUERY = gql`
  query {
    permissions {
      id
      key
    }
  }
`;

export const usePermissions = () => {
  const { data, loading, error } = useQuery(ALL_PERMISSIONS_QUERY, {
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.permissions
      : []
  ), [data, loading, error]);
};

export const usePermissionsMap = () => {
  const permissions = usePermissions();
  return permissions.reduce((acc, curr) => {
    acc[curr.key] = curr.id;
    return acc;
  }, {});
};

export const usePermissionsIDMap = () => {
  const permissions = usePermissions();
  return permissions.reduce((acc, curr) => {
    acc[curr.id] = curr.key;
    return acc;
  }, {});
};

const ALL_ROLES_QUERY = gql`
  query {
    roles {
      id
      name
      permissions {
        id
      }
    }
  }
`;

export const useRoles = () => {
  const { data, loading, error } = useQuery(ALL_ROLES_QUERY);
  return useMemo(() => (
    (!loading && !error)
      ? data.roles
      : []
  ), [data, loading, error]);
}
