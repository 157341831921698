import React from 'react';
import { useRoles } from 'graphql/common';
import {
  Field as FormikField,
} from 'formik';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const CompanyGroupBasicForm = ({
  values,
  setValues,
  errors,
}) => {
  const roles = useRoles();
  return (
    <Modal.Body>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          as={FormikField}
          id="name"
          name="name"
          disabled={true}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Role</Form.Label>
        {roles.map(r => (
          <FormikField
            key={`role-${r.id}`}
            component={Form.Check}
            type="radio"
            id="role"
            name={`role${r.name}`}
            label={r.name}
            checked={values.role && values.role.id === r.id}
            onChange={() => setValues({ ...values, role: r })}
          />
        ))}
      </Form.Group>
    </Modal.Body>
  );
};

export default CompanyGroupBasicForm;
