import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {
  Field as FormikField,
} from 'formik';

const CompanyBasicForm = ({
  values,
  setValues,
  errors
}) => (
  <Modal.Body>
    <Form.Group>
      <Form.Label>Name</Form.Label>
      <Form.Control
        as={FormikField}
        id="name"
        name="name"
        isInvalid={!!errors.name}
      />
      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <FormikField
        component={Form.Check}
        type="checkbox"
        id="status"
        name="status"
        label="Active"
        checked={values.status === 'ACTIVE'}
        onChange={() => setValues({ ...values, status: values.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' }) }
      />
    </Form.Group>
    <Form.Group>
      <FormikField
        component={Form.Check}
        type="checkbox"
        id="paid_customer"
        name="paid_customer"
        label="Paid Customer"
        checked={values.paid_customer}
        onChange={() => setValues({...values, paid_customer: !values.paid_customer})}
      />
    </Form.Group>
    <Form.Group>
      <Form.Label>Province/State</Form.Label>
      <Form.Control
        as={FormikField}
        id="province_state"
        name="province_state"
      />
    </Form.Group>
  </Modal.Body>
);

export default CompanyBasicForm;
