import { useMemo, useCallback } from 'react';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const SUBCONTRACTORS_CLIENTS_QUERY = gql`
  query companySubcontractorsClients($companyID: ID!) {
    companySubcontractorsClients(companyID: $companyID) {
      companySubcontractor {
        subcontractor {
          id 
          name 
          status
        }
        operatorCount
        available
      }
      companyClient {
        client {
          id
          name
        }
        permissions {
          hotDispatch {
            permission
            showHaulerRate
          }
        }
      }
    }
  }
`;

export const useCompanySubcontractorsClients = (companyID) => {
  const { data, loading, error } = useQuery(SUBCONTRACTORS_CLIENTS_QUERY, {
    variables: {
      companyID,
    },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companySubcontractorsClients
      : []
  ), [data, loading, error]);
}

const BULK_ADD_SUBCONTRACTORS = gql`
  mutation bulkAddSubcontractors($companyID: ID!, $input: [BulkAddSubcontractorInput!]!) {
    bulkAddSubcontractors(companyID: $companyID, input: $input) {
      id
      name
    }
  }
`;

export const useBulkAddSubcontractors = () => {
  const [bulkAddSubcontractors] = useMutation(BULK_ADD_SUBCONTRACTORS);
  return useCallback((companyID, input) => (
    bulkAddSubcontractors({
      variables: {
        companyID,
        input,
      },
      refetchQueries: [{ query: SUBCONTRACTORS_CLIENTS_QUERY, variables: { companyID } }],
    })
  ), [bulkAddSubcontractors]);
};

const ADD_SUBCONTRACTOR = gql`
  mutation addSubcontractor($input: SubcontractorInput!) {
    addSubcontractor(input:$input) {
      id
      company {
        id
      }
    }
  }
`;

export const useAddSubcontractor = () => {
  const [addSubcontractor] = useMutation(ADD_SUBCONTRACTOR);
  return useCallback((companyID, input) => (
    addSubcontractor({
      variables: {
        input,
      },
      refetchQueries: [{ query: SUBCONTRACTORS_CLIENTS_QUERY, variables: { companyID } }],
    })
  ), [addSubcontractor]);
};

const ADD_CLIENT = gql`
  mutation addCompanyClient($companyID: ID!, $clientCompanyID: ID!) {
    addCompanyClient(companyID: $companyID, clientCompanyID: $clientCompanyID) {
      id
    }
  }
`;

export const useAddClient = () => {
  const [addClient] = useMutation(ADD_CLIENT);
  return useCallback((companyID, clientCompanyID) => (
    addClient({
      variables: {
        companyID,
        clientCompanyID,
      },
      refetchQueries: [{ query: SUBCONTRACTORS_CLIENTS_QUERY, variables: { companyID } }],
    })
  ), [addClient]);
};

const SUBCONTRACTOR_BY_ID = gql`
  query subcontractorByID($id: ID!) {
    subcontractorByID(id: $id) {
      subcontractor {
        id
        name
      }
      id
      priority
      operatorCount
      available
    }
  }
`;

export const useSubcontractorByID = (subcontractorID) => {
  const { data, loading, error } = useQuery(SUBCONTRACTOR_BY_ID, {
    variables: {
      id: subcontractorID,
    },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.subcontractorByID
      : null
  ), [data, loading, error]);
};

const REMOVE_SUBCONTRACTOR_CLIENT = gql`
  mutation removeSubcontractorClients($input:RemoveSubcontractorClientInput!) {
    removeSubcontractorClients(input: $input) {
      companySubcontractor {
        subcontractor {
          id 
          name 
          status
        }
        operatorCount
        available
      }
      companyClient {
        client {
          id
          name
        }
        permissions {
          hotDispatch {
            permission
            showHaulerRate
          }
        }
      }
    }
  }`;

export const useRemoveSubcontractorClients = () => {
  const [removeSubcontractorClient] = useMutation(REMOVE_SUBCONTRACTOR_CLIENT);
  return useCallback((companyID, input) =>(
    removeSubcontractorClient({
      variables: {
        input,
      },
      refetchQueries: [{ query: SUBCONTRACTORS_CLIENTS_QUERY, variables: { companyID } }],
    })
  ),[removeSubcontractorClient]);
};

const CLIENTS_BY_COMPANY_ID = gql`
  query companyClients($companyID: ID!) {
    companyClients(companyID: $companyID) {
      id
      permissions {
        hotDispatch {
          permission
          showHaulerRate
        }
      }
      client {
        id
        name
      }
    }
  }
`;

export const useClientsByCompanyID = (companyID) => {
  const { data, loading, error } = useQuery(CLIENTS_BY_COMPANY_ID, {
    variables: {
      companyID: companyID,
    },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyClients
      : null
  ), [data, loading, error]);
};

const UPDATE_COMPANY_CLIENT_BY_ID = gql `
  mutation updateCompanyClient($input: UpdateCompanyClientInput!) {
    updateCompanyClient(input: $input) {
      id
    }
  }
`;

export const useUpdateCompanyClientByID = () => {
  const [updateCompanyClient] = useMutation(UPDATE_COMPANY_CLIENT_BY_ID);
  return useCallback((companyID, input) =>(
    updateCompanyClient({
      variables: {
        input,
      },
      refetchQueries: [{ query: CLIENTS_BY_COMPANY_ID, variables: { companyID } }],
    })
  ),[updateCompanyClient]);
};
