import { useMemo, useCallback } from 'react';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const COMPANIES_QUERY = gql`
  query companies {
    companies {
      id
      name
      status
      province_state
      paid_customer
    }
  }
`;

export const useCompanies = () => {
  const { data, loading, error } = useQuery(COMPANIES_QUERY);
  return useMemo(() => (
    (!loading && !error)
      ? data.companies
      : []
  ), [data, loading, error]);
}

export const COMPANY_QUERY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      status
      province_state
      paid_customer
    }
  }
`;

export const useCompany = (companyID) => {
  const { data, loading, error } = useQuery(COMPANY_QUERY, {
    variables: {
      id: companyID,
    },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.company
      : null
  ), [data, loading, error]);
}

export const COMPANY_UPDATE_MUTATION = gql`
  mutation updateCompany($input: CompanyInput!) {
    updateCompany(input: $input){
      id
      name
      status
      province_state
      paid_customer
    }
  }
`;

export const COMPANY_ADD_MUTATION = gql`
  mutation addCompany($input: CompanyInput!) {
    addCompany(input: $input){
      id
      name
      status
      province_state
      paid_customer
    }
  }
`;

export const COMPANY_REPROCESS_KPI = gql`
  mutation reprocessKpi($companyID: ID!, $from: String!, $to: String!) {
    reprocessKpi(companyID: $companyID, from: $from, to: $to) {
      num_reprocessed
      seconds
    }
  }
`;

export const COMPANY_PENDING_ASSIGNMENTS = gql`
 query pendingAssignments($companyID: ID!) {
    pendingAssignments(companyID: $companyID) {
      assignmentID
      projectName
      companyName
      firstName
      lastName
    }
  }
`;

//acceptPendingAssignments(assignmentIDs:[Int!]!):[AssignmentWithHire!]!
const ACCEPT_PENDING_ASSIGNMENTS = gql`
  mutation acceptPendingAssignments($assignmentIDs: [Int!]!) {
    acceptPendingAssignments(assignmentIDs: $assignmentIDs) {
      assignmentID
      projectName
      companyName
      firstName
      lastName
    }
  }
`;

export const useAcceptPendingAssignments = () => {
  const [acceptPendingAssignments] = useMutation(ACCEPT_PENDING_ASSIGNMENTS);
  return useCallback((assignmentIDs) => (
    acceptPendingAssignments({
      variables: {
        assignmentIDs,
      },
    })
  ), [acceptPendingAssignments]);
};

const COMPANY_BY_NAME = gql`
  query companyByName($companyName: String!) {
    companyByName(companyName: $companyName) {
      id
      name
      status
    }
  }
`;

export const useCompanyByName = (companyName) => {
  const { data, loading, error } = useQuery(COMPANY_BY_NAME, { variables: { companyName } });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyByName
      : null
  ), [data, loading, error])
}

const UPDATE_COMPANY_PERMISSIONS = gql`
  mutation updateCompanyPermissions($companyID: ID!, $input: CompanyPermissionsInput!) {
    updateCompanyPermissions(companyID: $companyID, input: $input) {
      id
      key
    }
  }
`;

export const useUpdateCompanyPermissions = () => {
  const [updateCompanyPermissions] = useMutation(UPDATE_COMPANY_PERMISSIONS);
  return useCallback((companyID, input) => (
    updateCompanyPermissions({
      variables: {
        companyID,
        input,
      },
    })
  ), [updateCompanyPermissions]);
};

const COMPANY_PERMISSIONS_QUERY = gql`
  query companyPermissions($companyID: ID!){
    companyPermissions(companyID: $companyID) {
      id,
      key
    }
  }
`;

export const useCompanyPermissions = (companyID) => {
  const { data, loading, error } = useQuery(COMPANY_PERMISSIONS_QUERY, {
    variables: { companyID },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyPermissions
      : []
  ), [data, loading, error]);
};