import React from 'react';
import Table from 'react-bootstrap/Table';
import {
  useCompanyGroupsForCompany,
} from 'graphql/company_groups';

const CompanyGroupRow = ({ companyGroup, setCompanyGroupDetailsVisible, setSelectedCompanyGroupID, setCompanyGroupDetailsAction }) => (
  <tr
    onClick={() => {
      setSelectedCompanyGroupID(companyGroup.id);
      setCompanyGroupDetailsAction('update');
      setCompanyGroupDetailsVisible(true);
    }}
  >
    <td>{companyGroup.id}</td>
    <td>{companyGroup.name}</td>
    <td className={companyGroup.hasAdminRead ? 'text-success' : 'text-danger'}>{companyGroup.hasAdminRead ? 'YES' : 'NO'}</td>
    <td className={companyGroup.hasAdminWrite ? 'text-success' : 'text-danger'}>{companyGroup.hasAdminWrite ? 'YES' : 'NO'}</td>
    <td className={companyGroup.hasAdminDelete ? 'text-success' : 'text-danger'}>{companyGroup.hasAdminDelete ? 'YES' : 'NO'}</td>
  </tr>
);

const CompanyGroupsList = ({
  companyID,
  setSelectedCompanyGroupID,
  companyGroupDetailsAction,
  setCompanyGroupDetailsAction,
  companyGroupDetailsVisible,
  setCompanyGroupDetailsVisible,
}) => {
  const companyGroups = useCompanyGroupsForCompany(companyID);
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Admin Read</th>
            <th>Admin Write</th>
            <th>Admin Delete</th>
          </tr>
        </thead>
        <tbody>
          {!!companyGroups && companyGroups.map(cg => (
            <CompanyGroupRow
              key={cg.id}
              companyGroup={cg}
              {...{
                setSelectedCompanyGroupID,
                setCompanyGroupDetailsAction,
                setCompanyGroupDetailsVisible
              }}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default CompanyGroupsList;
