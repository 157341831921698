import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classnames from 'classnames';
import { Impersonation } from 'tabs/Impersonation';
import { Accounts } from 'tabs/Accounts';
import { Companies } from 'tabs/Companies';
import { CompanyGroups } from 'tabs/CompanyGroups';
import { Subcontractors } from 'tabs/Subcontractors';

import {
  useWhoAmI,
  useWriteError,
} from 'graphql/common';

export const LoadingGate = ({ whoami, error, RenderComponent, ...rest }) => {
  const history = useHistory();
  const writeError = useWriteError();
  useEffect(() => {
    if (error) {
      writeError(JSON.stringify(error.message));
      history.push('/login');
    }
  }, [error, writeError, history, whoami]);
  if (!whoami) {
    return null;
  }
  return <RenderComponent {...{history}} {...rest} />
};

const useTabURL = () => {
  const location = useLocation();
  const history = useHistory();
  const activeKey = location.pathname && location.pathname !== '/'
    ? location.pathname.replace('/', '')
    : 'impersonate';
  const setActiveKey = (key) => {
    history.push({
      pathname: `/${key}`,
    });
  }
  return [activeKey, setActiveKey];
}

export const Admin = ({ history }) => {
  const [whoami, error] = useWhoAmI();
  const [activeKey, setActiveKey] = useTabURL();

  return (
    <div>
      <Tab.Container
        id="tabs"
        defaultActiveKey="impersonate"
        activeKey={activeKey}
        onSelect={v => setActiveKey(v)}
      >
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="impersonate">Impersonation</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="companies">Companies</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="accounts">Accounts</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="companyGroups">Company Groups</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="subcontractors">Relationships</Nav.Link>
          </Nav.Item>
          <Nav.Item>
          </Nav.Item>
          <Nav.Item className={classnames("d-flex", "align-items-center", "ml-auto")}>
            {!error && whoami && whoami.id > 0 &&
                <div>
                  You are logged in as {`${whoami.firstName} ${whoami.lastName} `}
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>This will log you out of Dispatcher as well</Tooltip>}>
                    <Button variant="outline-danger" onClick={() => history.push("/logout")}>Log out</Button>
                  </OverlayTrigger>
                </div>
            }
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="impersonate">
            <Impersonation />
          </Tab.Pane>
          <Tab.Pane eventKey="companies" unmountOnExit={true} mountOnEnter={true}>
            <Companies />
          </Tab.Pane>
          <Tab.Pane eventKey="accounts" unmountOnExit={true} mountOnEnter={true}>
            <Accounts />
          </Tab.Pane>
          <Tab.Pane eventKey="companyGroups" unmountOnExit={true} mountOnEnter={true}>
            <CompanyGroups />
          </Tab.Pane>
          <Tab.Pane eventKey="subcontractors" unmountOnExit={true} mountOnEnter={true}>
            <Subcontractors />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export const MainApp = () => {
  const [whoami, error] = useWhoAmI();

  return (
    <LoadingGate
      RenderComponent={Admin}
      {...{ whoami, error }}
    />
  );
}
