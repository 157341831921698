import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { actions } from 'utils/form';
import { useDefaultAccountSettings } from 'graphql/accounts';
import styles from './styles.module.scss';

const languages = {
  ENGLISH: 'en',
  ESPANOL: 'es',
};

const LanguageSelect = ({ values, setValues }) => (
  <>
    <Form.Label>Language: </Form.Label>
    <select
      id="language"
      className={styles.languageSelect}
      value={values.accountSettings.language}
      onChange={(e) => setValues({
        ...values,
        accountSettings: {
          ...values.accountSettings,
          language: e.target.value,
        },
      })}
    >
      {Object.keys(languages).map(l => (
        <option
          key={`language-${languages[l]}`}
          value={languages[l]}
        >
          {l}
        </option>
      ))}
    </select>
  </>
);

const notificationOptions = ["Push", "Text", "None"];

const NotificationSettingItem = ({ name, setting, values, setValues }) => (
  <>
    <Form.Label className="mr-3">{`${name}: `}</Form.Label>
    <div>
      {notificationOptions.map(o => (
        <Form.Check
          key={`nsoption-${name}-${o}`}
          inline
          type="radio"
          label={o}
          value={setting === o}
          checked={setting === o}
          onChange={(e) => {
            if (e.target.checked) {
              setValues({
                ...values,
                accountSettings: {
                  ...values.accountSettings,
                  notificationSettings: {
                    ...values.accountSettings.notificationSettings,
                    [name]: o
                  }
                },
              });
            }
          }}
        />
      ))}
    </div>
  </>
);

const AccountSettings = ({
  action,
  values,
  setValues,
}) => {
  const defaultSettings = useDefaultAccountSettings();
  const nsObj = values.accountSettings && values.accountSettings.notificationSettings
    ? values.accountSettings.notificationSettings
    : {};
  let notificationSettings;
  const { __typename, ...accNS } = nsObj;
  notificationSettings = accNS;
  const resetToDefault = () => {
    setValues({
      ...values,
      accountSettings: {
        ...defaultSettings,
      },
    });
  };

  if (action ===  actions.ADD) {
    return null;
  }
  if (action ===  actions.UPDATE || action === actions.BULKUPDATE) {
    return (
      <Modal.Body>
        <Form.Group>
          <div className={styles.settingsHeader}>
            <h5>Account Settings</h5>
            <Button size="sm" variant="outline-warning" onClick={resetToDefault}>
              Reset Settings Fields to Default
            </Button>
          </div>
          {action === actions.BULKUPDATE && (
            <Alert variant="info">Default settings are prepopulated when modifying accounts in bulk</Alert>
          )}
          <div className={styles.settingsTable}>
            <LanguageSelect values={values} setValues={setValues} />
            {notificationSettings && Object.keys(notificationSettings).map(ns => (
              <NotificationSettingItem
                key={`nsitem-${ns}`}
                name={ns}
                setting={notificationSettings[ns]}
                values={values}
                setValues={setValues}
              />
            ))}
          </div>
        </Form.Group>
      </Modal.Body>
    );
  }
  return null;
};

export default AccountSettings;
