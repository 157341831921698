import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { CenterContainer } from "App";
import { CompanySelect, GenericObjectFormModal } from "components/common";
import { AccountDetailsForm, AddExistingAccountModal } from "./components";
import { useDefaultAccountSettings } from "graphql/accounts";
import { useCompanies, useAccountsByCompany } from "graphql/common";
import useSelectIDs from "utils/useSelectIDs";
import { actions } from "utils/form";

const AccountRow = ({
  account,
  selected,
  toggleSelect,
  setVisible,
  setSelectedAccountID,
  setAction,
}) => {
  const onClick = () => {
    setSelectedAccountID(account.id);
    setAction(actions.UPDATE);
    setVisible(true);
  };
  return (
    <tr>
      <td onClick={() => toggleSelect(account.id)}>
        <Form.Check
          checked={selected}
          onChange={() => toggleSelect(account.id)}
        />
      </td>
      <td onClick={onClick}>{account.id}</td>
      <td onClick={onClick}>{account.phoneNumber}</td>
      <td onClick={onClick}>{account.firstName}</td>
      <td onClick={onClick}>{account.lastName}</td>
      <td
        className={account.disabled ? "text-danger" : "text-success"}
        onClick={onClick}
      >
        {account.disabled ? "DISABLED" : "ENABLED"}
      </td>
    </tr>
  );
};

const AccountsList = ({
  accounts,
  accountIDs,
  setVisible,
  selectedAccountID,
  setSelectedAccountID,
  setAction,
  existingAccountModalVisible,
  setExistingAccountModalVisible,
  selectedAccounts,
  selectAll,
  isSelected,
  toggleSelect,
}) => {
  if (accounts) {
    return (
      <>
        <ButtonToolbar className="mb-2">
          <Button
            className="mr-2"
            variant="success"
            onClick={() => {
              setAction(actions.ADD);
              setVisible(true);
            }}
          >
            + Add NEW Account to this Company
          </Button>
          <Button
            className="mr-2"
            variant="primary"
            onClick={() => setExistingAccountModalVisible(true)}
          >
            + Add Existing Account to this Company
          </Button>
          <Button
            disabled={selectedAccounts.length === 0}
            variant="info"
            onClick={() => {
              setAction(actions.BULKUPDATE);
              setVisible(true);
            }}
          >
            Bulk Edit Selected Accounts ({selectedAccounts.length})
          </Button>
        </ButtonToolbar>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <Form.Check
                  checked={accountIDs.length === selectedAccounts.length}
                  onChange={() => selectAll(accountIDs)}
                />
              </th>
              <th>ID</th>
              <th>PN</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((a) => (
              <AccountRow
                key={a.id}
                account={a}
                selected={isSelected(a.id)}
                toggleSelect={toggleSelect}
                {...{ setVisible, setSelectedAccountID, setAction }}
              />
            ))}
          </tbody>
        </Table>
      </>
    );
  }
  return null;
};

export const useDefaultAccount = () => {
  const defaultAccount = {
    id: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    disabled: false,
    companyGroups: [],
  };
  const defaultAccountSettings = useDefaultAccountSettings();
  return {
    ...defaultAccount,
    accountSettings: defaultAccountSettings,
    permissions: [],
  };
};

const Accounts = () => {
  const companies = useCompanies(true);
  const [visible, setVisible] = useState(false);
  const [existingAccountModalVisible, setExistingAccountModalVisible] =
    useState(false);
  const [action, setAction] = useState(actions.UPDATE);
  const [selectedAccountID, setSelectedAccountID] = useState(0);
  const [companyID, setCompanyID] = useState(0);
  const accounts = useAccountsByCompany(companyID);
  const accountIDs = accounts.map((a) => a.id);

  const [selectedAccounts, selectAll, toggleSelect, isSelected] =
    useSelectIDs(accountIDs);
  const resetForm = () => setSelectedAccountID(0);
  useEffect(() => {
    if (companyID === 0 && companies.length > 0) {
      setCompanyID(companies[0].id);
    }
  }, [companies, companyID]);
  return (
    <CenterContainer>
      <Card className="my-2">
        <Card.Body>
          Select a company to view accounts
          <CompanySelect companies={companies} setCompanyID={setCompanyID} />
          <AccountsList
            {...{
              accounts,
              accountIDs,
              visible,
              setVisible,
              selectedAccountID,
              setSelectedAccountID,
              setAction,
              existingAccountModalVisible,
              setExistingAccountModalVisible,
              selectedAccounts,
              selectAll,
              toggleSelect,
              isSelected,
            }}
          />
          <AddExistingAccountModal
            {...{
              companyID,
              existingAccountModalVisible,
              setExistingAccountModalVisible,
            }}
          />
          <GenericObjectFormModal
            visible={visible}
            setVisible={setVisible}
            action={action}
            FormComponent={AccountDetailsForm}
            {...{
              resetForm,
              selectedAccountID,
              selectedAccounts,
              companyID,
            }}
          />
        </Card.Body>
      </Card>
    </CenterContainer>
  );
};

export default Accounts;
