import React from 'react';
import Table from 'react-bootstrap/Table';

const AssignmentRow = ({
  assignment,
}) => {
  return (
    <tr>
      <td>{assignment.assignmentID}</td>
      <td>{assignment.projectName}</td>
      <td>{assignment.companyName}</td>
      <td>{assignment.firstName}</td>
      <td>{assignment.lastName}</td>
    </tr>
  );
}

const AssignmentTable = ({
    assignments,
  }) => {
    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Assignment ID</th>
              <th>Project Name</th>
              <th>Company Name</th>
              <th>Hire First Name</th>
              <th>Hire Last Name</th>
            </tr>
          </thead>
          <tbody>
            { !!assignments &&
                assignments.map(c => (
                  <AssignmentRow key={c.assignmentID} assignment={c}/>
                ))
            }
          </tbody>
        </Table>
      </>
    );
  }

  export default AssignmentTable;