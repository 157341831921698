import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { CenterContainer } from 'App';
import {
  useCompanies,
} from 'graphql/companies';
import {
  useUpdateCompanyGroup,
  useCompanyGroup,
} from 'graphql/company_groups';
import { GenericObjectFormModal, CompanySelect } from 'components/common';
import { CompanyGroupsList, CompanyGroupDetailsForm } from './components';

export const defaultCompanyGroup = {
  id: '',
  name: '',
  hasAdminWrite: false,
  hasAdminRead: false,
  hasAdminDelete: false,
}

const CompanyGroups = () => {
  const [companyID, setCompanyID] = useState(0);
  const [selectedCompanyGroupID, setSelectedCompanyGroupID] = useState(0);
  const companies = useCompanies();
  const [companyGroupDetailsAction, setCompanyGroupDetailsAction] = useState('update');
  const [companyGroupDetailsVisible, setCompanyGroupDetailsVisible] = useState(false);
  const updateCompanyGroup = useUpdateCompanyGroup();
  const companyGroup = useCompanyGroup(selectedCompanyGroupID);

  return (
    <CenterContainer>
      <Card className="my-2">
        <Card.Body>
          <CompanySelect
            companies={companies}
            setCompanyID={setCompanyID}
          />
          <CompanyGroupsList
            {...{
              companyID,
              setSelectedCompanyGroupID,
              companyGroupDetailsAction,
              setCompanyGroupDetailsAction,
              companyGroupDetailsVisible,
              setCompanyGroupDetailsVisible,
              updateCompanyGroup,
            }}
          />
          <GenericObjectFormModal 
            visible={companyGroupDetailsVisible}
            setVisible={setCompanyGroupDetailsVisible}
            action={companyGroupDetailsAction}
            FormComponent={CompanyGroupDetailsForm}
            companyGroup={companyGroup}
            companyID={companyID}
          />
        </Card.Body>
      </Card>
    </CenterContainer>
  );
}

export default CompanyGroups;
