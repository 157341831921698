import { useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const IMPERSONATING_AS_QUERY = gql`
  query ImpersonatingAs {
    impersonation {
      id
      firstName
      lastName
    }
  }
`;

export const useImpersonatingAs = () => {
  const { data, loading, error } = useQuery(IMPERSONATING_AS_QUERY, {
    fetchPolicy: 'network-only',
  });

  return useMemo(() => (
    (!loading && !error)
      ? data.impersonation
      : {}
  ), [data, loading, error]);
}

const IMPERSONATE_MUTATION = gql`
  mutation Impersonate($id: ID!) {
    impersonate(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const useImpersonate = () => {
  const [impersonate] = useMutation(
    IMPERSONATE_MUTATION,
    { refetchQueries: ['ImpersonatingAs'] },
  );
  return useCallback((impersonateID) => {
    return impersonate({ variables: { id: impersonateID } });
  }, [impersonate]);
}

const STOP_IMPERSONATING_MUTATION = gql`
  mutation StopImpersonating {
    stopImpersonating {
      id
      firstName
      lastName
    }
  }
`;

export const useStopImpersonating = () => {
  const [stopImpersonating] = useMutation(
    STOP_IMPERSONATING_MUTATION,
    { refetchQueries: ['ImpersonatingAs'] },
  );
  return useCallback(() => {
    stopImpersonating();
  }, [stopImpersonating]);
}

export const ACCOUNTS_BY_COMPANY_GROUP = gql`
  query AccountsByCompanyGroup($companyID: ID!, $companyGroupName: String = "") {
    accountsByCompanyGroup(companyID: $companyID, companyGroupName: $companyGroupName) {
      id
      firstName
      lastName
    }
  }
`;

export const useAccountsByCompanyGroup = (companyID, companyGroupName = "") => {
  const { data, loading, error } = useQuery(ACCOUNTS_BY_COMPANY_GROUP, {
    variables: {
      companyID : companyID,
      companyGroupName: companyGroupName,
    },
    fetchPolicy: 'network-only',
  });

  return useMemo(() => (
    (!loading && !error)
      ? data.accountsByCompanyGroup
      : []
  ), [data, loading, error]);
};

