import React, { useState } from 'react';
import { CenterContainer } from 'App';
import Card from 'react-bootstrap/Card';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { SubcontractorList } from './components';
import { SubcontractorFileUpload } from './components';
import AddClientForm from './components/AddClientForm';
import AddExistingClientForm from './components/AddExistingClientForm';
import { useCompanies } from 'graphql/common';
import { ConfirmCard, GenericObjectFormModal } from 'components/common';
import { useCompanySubcontractorsClients, useRemoveSubcontractorClients } from 'graphql/subcontractors';
import useSelectIDs from 'utils/useSelectIDs';
import { useWriteError } from 'graphql/common';
import Alert from 'react-bootstrap/Alert';
import { ClientList } from './components';

export const Subcontractors = () => {
  const writeError = useWriteError();
  const [fileUploadVisible, setFileUploadVisible] = useState(false);
  const [addClientFormVisible, setAddClientFormVisible] = useState(false);
  const [addExistingClientFormVisible, setAddExistingClientFormVisible] = useState(false);
  const [confirmCardVisible, setConfirmCardVisible] = useState(false);
  const [statusMsg, setStatusMsg] = useState("")
  const companies = useCompanies(false);
  const [companyID, setCompanyID] = useState(0);
  if (!companyID && companies.length > 0) {
    setCompanyID(companies[0].id);
  }
  const subcontractors = useCompanySubcontractorsClients(companyID);
  const subcontractorsIDs = subcontractors.map(a => a.id);
  const [selectedIDs, , toggleSelect, selectNone] = useSelectIDs(subcontractorsIDs);
  var selectedSubcontractors = useState([])
  const setSelectedSubcontractors = (selectedIDs) => {
    selectedSubcontractors = subcontractors.filter(({companySubcontractor:{subcontractor:{id}}})  => {
      return selectedIDs.includes(id)  
    })    
  }
  const reset = () => {
    setConfirmCardVisible(false);
    setAddClientFormVisible(false);
    setSelectedSubcontractors([]);
    selectNone(selectedIDs);
  }
  setSelectedSubcontractors(selectedIDs)
 
  const removeSubcontractorClient = useRemoveSubcontractorClients();
  const submitRemoveSubcontractorClient = () => {
    const input = {
      companyID: companyID, 
      subcontractorIDs: selectedIDs,
    };
    removeSubcontractorClient(companyID, input)
      .then(() => {
        reset();
      })
      .catch(err => {
        reset();
        writeError(err);
        setStatusMsg(err.message);
      });
  };
  const company = companies.find(c => c.id === companyID)
  const companyName = company ? company.name : "unknown"

  return (
    <CenterContainer>
      <SubcontractorFileUpload
        companyID={companyID}
        visible={fileUploadVisible}
        setVisible={setFileUploadVisible}
      />
      <GenericObjectFormModal 
        visible={addExistingClientFormVisible}
        setVisible={setAddExistingClientFormVisible}
        FormComponent={AddExistingClientForm}
        companyID={companyID}
        companyName={companyName}
        companies={companies}
        setStatusMsg={setStatusMsg}
        modalTitle={"Add Client to " + companyName}
      />
      <GenericObjectFormModal 
        visible={addClientFormVisible}
        setVisible={setAddClientFormVisible}
        FormComponent={AddClientForm}
        companyID={companyID}
        companyName={companyName}
        setStatusMsg={setStatusMsg}
        modalTitle={"Create and Add Client to " + companyName}
      />

      <Card className="my-2">
        <Card.Body>
          {!!statusMsg && <Alert variant="danger">{statusMsg}</Alert>}

          <SubcontractorList
            companies={companies}
            subcontractorClients={subcontractors}
            companyID={companyID}
            selectedIDs={selectedIDs}
            setCompanyID={setCompanyID}
            setFileUploadVisible={setFileUploadVisible}
            setConfirmCardVisible={setConfirmCardVisible}
            setAddClientFormVisible={setAddClientFormVisible}
            toggleSelect={toggleSelect}
            setStatus={setStatusMsg}
          />

          {confirmCardVisible && 
            <ConfirmCard
              text={`Are you sure you want to remove these subcontractor <-> client relationships?`}
              buttonText="Confirm Removal"
              variant="danger"
              setVisible={setConfirmCardVisible}
              callback={submitRemoveSubcontractorClient}
            >
              {(!!selectedSubcontractors && selectedSubcontractors.map(s => 
                  <div key={s.companySubcontractor.subcontractor.id}>{s.companySubcontractor.subcontractor.name}</div>
              ))}
            </ConfirmCard>
          }

          <div />
          <ButtonGroup className="mb-2">
            <Button
              className="mr-2"
              variant="info"
              onClick={() => {
                setAddExistingClientFormVisible(true);
              }}
            >
              Add Existing Company as Client 
            </Button>
            <Button
              className="mr-2"
              variant="success"
              onClick={() => {
                setAddClientFormVisible(true);
              }}
            >
              Add New Company as Client 
            </Button>
          </ButtonGroup>
          <div />
          <ClientList
            companyID={companyID}
            setStatusMsg={setStatusMsg}
          />    
        </Card.Body>
      </Card>
    </CenterContainer>
  );
};
