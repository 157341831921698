import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const ConfirmCard = ({
  text,
  buttonText,
  variant = "danger",
  setVisible,
  callback,
  children,
}) => (
  <Card className="mt-2">
    <Card.Body>
      <Card.Text>{text}</Card.Text>
      <div>{children}</div>
      <Button variant="outline-danger" onClick={() => setVisible(false)}>Cancel</Button>
      <Button variant={variant} onClick={callback}>{buttonText}</Button>
    </Card.Body>
  </Card>
);

export default ConfirmCard;
