import React, { useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { usePermissionsMap } from 'graphql/common';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  Formik,
  Form as FormikForm,
} from 'formik';
import {
  CompanyPermissions,
} from 'utils/permissions';
import {
  ConfirmCard,
  ChangedPermissions,
  PermissionsForm,
} from 'components/common';
import {
  COMPANY_UPDATE_MUTATION,
  COMPANY_ADD_MUTATION,
  useUpdateCompanyPermissions,
  useCompanyPermissions,
} from 'graphql/companies';
import { defaultCompany } from 'tabs/Companies/Companies';
import { CompanyBasicForm, ReprocessKPIForm, ActionsForm } from 'tabs/Companies/components';
import styles from 'components/common/styles.module.scss';

const CompanyDetailsSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Too short!').max(255, 'Too long!').required('Required'),
  //provinceState: Yup.string().min(1, 'Too Short!').max(10, "This should be the abbreviation").required('Required'),
});

const CompanyDetailsFormRender = ({
  values,
  setValues,
  isSubmitting,
  submitForm,
  handleSubmit,
  errors,
  status,
  setStatus,
  action,
  setVisible,
  oldPermissions,
  ...rest
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const submitCallback = () => {
    submitForm()
      .then(() => setVisible(false))
      .catch(e => setStatus({ error: e.message }));
  };
  return (
    <>
      <FormikForm>
        <Tabs className={styles.formTabs} mountOnEnter={true}>
          <Tab eventKey="basics" title="Basics">
            <CompanyBasicForm values={values} setValues={setValues} errors={errors} />
          </Tab>
          <Tab eventKey="Permissions" title="Permissions" disabled={action === 'add'}>
            <PermissionsForm
              values={values}
              setValues={setValues}
              errors={errors}
              availablePermissionGroups={CompanyPermissions}
            />
          </Tab>
          <Tab eventKey="reprocessKPI" title="Reprocess KPI">
            <ReprocessKPIForm company={values} />
          </Tab>
          <Tab eventKey="bulkAction" title="Actions">
            <ActionsForm company={values} />
          </Tab>
        </Tabs>
      </FormikForm>
      {!!status && !!status.error && <Alert variant="danger">{status.error}</Alert>}
      {!confirmVisible && (
        <Modal.Footer>
          <Button variant="danger" onClick={() => setVisible(false)}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => !isSubmitting && (
              action === 'update' ? setConfirmVisible(true) : handleSubmit()
            )}
          >
            {action === 'update' ? `Update` : `Add`}
          </Button>
        </Modal.Footer>
      )}
      {action === 'update' && confirmVisible && (
        <ConfirmCard
          text={`Are you sure you want to update the company ${values.name}?`}
          buttonText="Confirm Update"
          variant="primary"
          setVisible={setVisible}
          callback={submitCallback}
        >
          <ChangedPermissions
            oldPermissions={oldPermissions}
            newPermissions={values.permissions}
          />
        </ConfirmCard>
      )}
    </>
  );
};

const CompanyDetailsForm = ({
  company,
  setVisible,
  action,
}) => {
  const [updateCompany] = useMutation(COMPANY_UPDATE_MUTATION);
  const [addCompany] = useMutation(COMPANY_ADD_MUTATION, { refetchQueries: ['companies'] });
  const updateCompanyPermissions = useUpdateCompanyPermissions();
  const companyID = company ? company.id : '-1';
  const cPermissions = useCompanyPermissions(companyID);
  const oldPermissions = cPermissions.map(p => p.id);
  const permissions = usePermissionsMap();
  const availablePermissionIDs = Object.keys(CompanyPermissions).reduce((acc, curGroup) => (
    [...acc, ...CompanyPermissions[curGroup].map(pKey => permissions[pKey])]
  ), []);

  let cValues = defaultCompany;
  if (!!company){
    cValues = { ...cValues, ...company, permissions: cPermissions.map(p => p.id) };
  }

  const onSubmit = (values, { setSubmitting, setStatus } ) => {
    // Extract __typename because this was set when we initialized values
    const { __typename: _typeDiscard, permissions, ...input } = values;
    // Deny permissions are permissionIDs that are NOT in the permissions values field
    const denyPermissionIDs = availablePermissionIDs
      .filter(pID => !_.some(permissions, active => active === pID));
    const permissionsInput = {
      permissionIDs: permissions,
      denyPermissionIDs,
    };
    setSubmitting(true);
    if (action === 'update') {
      updateCompany({
        variables: {
          input
        }
      })
        .then(() => updateCompanyPermissions(values.id, permissionsInput))
        .then(() => {
          setVisible(false);
          setSubmitting(false);
        })
        .catch(err => setStatus({ error: err.message }));
    } else if (action === 'add') {
      const { __typename: _, permissions, ...input } = values;
      addCompany({
        variables: {
          input,
        },
      })
        .then(() => {
          setVisible(false);
          setSubmitting(false);
        })
        .catch(err => setStatus({ error: err.message }));
    }
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...cValues,
      }}
      validationSchema={CompanyDetailsSchema}
      onSubmit={onSubmit}
      children={props =>
        <CompanyDetailsFormRender
          action={action}
          setVisible={setVisible}
          oldPermissions={oldPermissions}
          {...props}
        />
      }
    />
  )
};

export default CompanyDetailsForm;
