import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import {
  COMPANY_PENDING_ASSIGNMENTS,
  useAcceptPendingAssignments,
} from "graphql/companies";
import AssignmentTable from "./AssignmentTable";
import { useLazyQuery } from "@apollo/react-hooks";
import Alert from "react-bootstrap/Alert";

const ActionsForm = ({ company }) => {
  const now = moment().format("YYYY-MM-DD");
  const acceptPendingAssignments = useAcceptPendingAssignments();
  const [assignments, setAssignments] = useState([]);
  const [isAcceptEnable, setAcceptEnable] = useState(true);
  const [assignmentIds, setAssignmentIds] = useState([]);
  const [status, setStatus] = useState("");
  const [acceptedAssignments, setAcceptedAssignments] = useState([]);

  const companyID = company.id;
  const [fetchPending, { data }] = useLazyQuery(COMPANY_PENDING_ASSIGNMENTS, {
    fetchPolicy: "network-only",
  });

  const handleAccept = () => {
    if (assignmentIds && assignmentIds.length > 0) {
      acceptPendingAssignments(assignmentIds)
        .then(() => {
          setStatus("");
          setAcceptedAssignments(data.pendingAssignments);
        })
        .catch((err) => setStatus(err.message));
    }
    setAcceptEnable(true);
  };

  useEffect(() => {
    if (data && data.pendingAssignments) {
      if (data.pendingAssignments.length === 0) {
        setStatus("No assignments found to accept");
        setAssignmentIds([]);
        setAssignments([]);
      } else {
        setAssignments(data.pendingAssignments);
        setAcceptEnable(false);

        const aids = data.pendingAssignments.map((a) => a.assignmentID);
        setAssignmentIds(aids);
        setStatus("");
      }
    }
  }, [data]);

  return (
    <Formik enableReinitialize={true}>
      <Modal.Body>
        {<Modal.Title>Auto Accept Assignments for {now}</Modal.Title>}
        <hr />
        <p>This modal will query any pending assignments for today only</p>
        <FormikForm>
          <Form.Group>
            <Button
              variant="primary"
              onClick={() => {
                fetchPending({ variables: { companyID } });
              }}
            >
              Query Pending Assignments
            </Button>
          </Form.Group>
          <AssignmentTable assignments={assignments} />
          Total pending assignments {assignments.length}
          <div />
          <Form.Group>
            <Button
              name="acceptPending"
              variant="success"
              disabled={isAcceptEnable}
              onClick={handleAccept}
            >
              Auto Accept Pending Assignments
            </Button>
            {status && <Alert variant="danger">{status}</Alert>}
          </Form.Group>
          <AssignmentTable assignments={acceptedAssignments} />
          Total accepted assignments {acceptedAssignments.length}
        </FormikForm>
      </Modal.Body>
    </Formik>
  );
};

export default ActionsForm;
