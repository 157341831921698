import React from 'react';
import Modal from 'react-bootstrap/Modal';

const GenericObjectFormModal = ({
  visible,
  setVisible,
  action,
  resetForm,
  FormComponent,
  modalTitle,
  ...rest
}) => (
  <Modal
    show={visible}
    onHide={() => {
      resetForm && resetForm();
      setVisible(false);
    }}
    size="lg"
  >
    <Modal.Header closeButton>
      <Modal.Title>{ modalTitle || "Details" }</Modal.Title>
    </Modal.Header>
    <FormComponent
      {...{
        setVisible,
        action,
        ...rest,
      }}
    />
  </Modal>
);

export default GenericObjectFormModal;
