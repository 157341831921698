import { useState } from 'react';

const useSelectIDs = (selectableIDs) => {
  const [selectedIDs, setSelectedIDs] = useState([]);

  const selectAll = () => {
    const idSet = new Set(selectableIDs);
    const selectedSet = new Set(selectedIDs);
    if (idSet.size === selectedSet.size) {
      setSelectedIDs([]);
    } else {
      setSelectedIDs(selectableIDs);
    }
  };

  const toggleSelect = (id) => {
    const newSet = new Set(selectedIDs);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setSelectedIDs(Array.from(newSet))
  };

  const isSelected = (id) => {
    const selectedSet = new Set(selectedIDs);
    return selectedSet.has(id);
  }

  const selectNone = () => {
    setSelectedIDs([]);
  };

  return [selectedIDs, selectAll, toggleSelect, isSelected, selectNone];
};

export default useSelectIDs;
