import { useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const ACCOUNT_QUERY = gql`
  query AccountById($id: ID!) {
    account(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      disabled
      accountSettings {
        language
        notificationSettings{
          acceptAssignment
          acceptProject
          declineProject
          requestNewProject
          requestReminderLowPriority
          requestReminderHighPriority
          assignmentReminderLowPriority
          assignmentReminderHighPriority
          subcontractorCancel
          subcontractorUpdate
          subdispatcherProjectReminder
          smartSchedulerJobComplete
          operatorConnectionLost
          stopLoggingLowPriority
          stopLoggingHighPriority
          assignmentDeclined
          addedAsProjectCollaborator
        }
      }
    }
  }
`;

export const useAccount = (id) => {
  const { data, loading, error } = useQuery(ACCOUNT_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only' ,
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.account
      : null
  ), [data, loading, error]);
};

const ACCOUNT_BY_PHONENUMBER_QUERY = gql`
  query AccountByPhoneNumber($phoneNumber: String!) {
    accountByPhoneNumber(phoneNumber: $phoneNumber) {
      id
      firstName
      lastName
      email
      phoneNumber
      disabled
    }
  }
`;

export const useAccountByPhoneNumber = (phoneNumber) => {
  const { data, loading, error } = useQuery(ACCOUNT_BY_PHONENUMBER_QUERY, {
    variables: {
      phoneNumber,
    },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.accountByPhoneNumber
      : null
  ), [data, loading, error]);
}

const ACCOUNT_UPDATE_MUTATION = gql`
  mutation UpdateAccount($input: AccountInput!) {
    updateAccount(input: $input) {
      __typename
      id
      firstName
      lastName
      email
      phoneNumber
      disabled
    }
  }
`;

export const useUpdateAccount = () => {
  const [updateAccount] = useMutation(ACCOUNT_UPDATE_MUTATION, {
    refetchQueries: ['CompanyAccounts', 'CompanyGroupsForAccount']
  });
  return useCallback((input) => (
    updateAccount({ variables: { input } })
  ), [updateAccount]);
};

const ACCOUNT_ADD_MUTATION = gql`
  mutation AddAccount($input: AccountInput!, $companyID: ID) {
    addAccount(input: $input, companyID: $companyID) {
      __typename
      id
      firstName
      lastName
      email
      phoneNumber
      disabled
    }
  }
`;

export const useAddAccount = () => {
  const [addAccount] = useMutation(ACCOUNT_ADD_MUTATION, {
    refetchQueries: ['CompanyAccounts', 'CompanyGroupsForAccount']
  });
  return useCallback((input, companyID) => (
    addAccount({ variables: { input, companyID } })
  ), [addAccount]);
};

const COMPANY_ACCOUNT_ADD_MUTATION = gql`
  mutation AddCompanyAccount($accountID: ID!, $companyID: ID!) {
    addCompanyAccount(accountID: $accountID, companyID: $companyID) {
      id
      accountID
      companyID
    }
  }
`;

export const useAddCompanyAccount = () => {
  const [addCompanyAccount] = useMutation(COMPANY_ACCOUNT_ADD_MUTATION, {
    refetchQueries: ['CompanyAccounts', 'CompanyGroupsForAccount']
  });
  return useCallback((accountID, companyID) => (
    addCompanyAccount({ variables: { accountID, companyID } })
  ), [addCompanyAccount]);
};

const COMPANY_ACCOUNT_REMOVE_MUTATION = gql`
  mutation RemoveCompanyAccount($accountID: ID!, $companyID: ID!) {
    removeCompanyAccount(accountID: $accountID, companyID: $companyID) {
      id
      accountID
      companyID
    }
  }
`;

export const useRemoveCompanyAccount = () => {
  const [removeCompanyAccount] = useMutation(COMPANY_ACCOUNT_REMOVE_MUTATION, {
    refetchQueries: ['CompanyAccounts', 'CompanyGroupsForAccount'],
  });
  return useCallback((accountID, companyID) => (
    removeCompanyAccount({
      variables: { accountID, companyID },
    })
  ), [removeCompanyAccount]);
};

const COMPANY_GROUPS_FOR_ACCOUNT_QUERY = gql`
  query CompanyGroupsForAccount($accountID: ID!, $companyID: ID) {
    companyGroupsForAccount(accountID: $accountID, companyID: $companyID) {
      id
      companyID
      name
    }
  }
`;

export const useCompanyGroupsForAccount = (accountID, companyID) => {
  const { data, loading, error } = useQuery(COMPANY_GROUPS_FOR_ACCOUNT_QUERY, {
    variables: { accountID, companyID },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyGroupsForAccount
      : []
  ), [data, loading, error]);
};

const ACCOUNT_COMPANY_GROUPS_UPDATE_MUTATION = gql`
  mutation UpdateAccountCompanyGroups($accountID: ID!, $companyID: ID!, $companyGroupIDs: [ID!]!){
    updateAccountCompanyGroups(accountID: $accountID, companyID: $companyID, companyGroupIDs: $companyGroupIDs) {
     id
     name
    }
  }
`;

export const useUpdateAccountCompanyGroups = () => {
  const [updateAccountCompanyGroups] = useMutation(ACCOUNT_COMPANY_GROUPS_UPDATE_MUTATION);
  return useCallback((accountID, companyID, companyGroupIDs) => (
    updateAccountCompanyGroups({
      variables: {
        accountID,
        companyID,
        companyGroupIDs,
      }
    })
  ), [updateAccountCompanyGroups]);
};

const ACCOUNT_SETTINGS_UPDATE_MUTATION = gql`
  mutation UpdateAccountSettings($accountID: ID!, $input: AccountSettingsInput!){
    updateAccountSettings(accountID: $accountID, input: $input){
      id
      accountSettings {
        language
        notificationSettings {
          acceptAssignment
          acceptProject
          declineProject
          requestNewProject
          requestReminderLowPriority
          requestReminderHighPriority
          assignmentReminderLowPriority
          assignmentReminderHighPriority
          subcontractorCancel
          subcontractorUpdate
          subdispatcherProjectReminder
          smartSchedulerJobComplete
          operatorConnectionLost
          stopLoggingLowPriority
          stopLoggingHighPriority
          assignmentDeclined
          addedAsProjectCollaborator
        }
      }
    }
  }
`;

export const useUpdateAccountSettings = () => {
  const [updateAccountSettings] = useMutation(ACCOUNT_SETTINGS_UPDATE_MUTATION);
  return useCallback((accountID, input) => (
    updateAccountSettings({
      variables: {
        accountID,
        input,
      }})
  ), [updateAccountSettings]);
};

const DEFAULT_ACCOUNT_SETTINGS = gql`
  query DefaultAccountSettings {
    defaultAccountSettings {
      language
      notificationSettings {
        acceptAssignment
        acceptProject
        declineProject
        requestNewProject
        requestReminderLowPriority
        requestReminderHighPriority
        assignmentReminderLowPriority
        assignmentReminderHighPriority
        subcontractorCancel
        subcontractorUpdate
        subdispatcherProjectReminder
        smartSchedulerJobComplete
        operatorConnectionLost
        stopLoggingLowPriority
        stopLoggingHighPriority
        assignmentDeclined
        addedAsProjectCollaborator
      }
    }
  }
`;

export const useDefaultAccountSettings = () => {
  const { data, loading, error } = useQuery(DEFAULT_ACCOUNT_SETTINGS);
  return useMemo(() => {
    if (!loading && !error) {
      return data.defaultAccountSettings;
    }
    return {};
  }, [data, loading, error]);
}

const BULK_UPDATE_ACCOUNTS_MUTATION = gql`
  mutation BulkUpdateAccounts($accountIDs: [ID!]!, $input: BulkUpdateAccountsInput!){
    bulkUpdateAccounts(accountIDs: $accountIDs, input: $input){
      id
      accountSettings {
        language
        notificationSettings {
          acceptAssignment
          acceptProject
          declineProject
          requestNewProject
          requestReminderLowPriority
          requestReminderHighPriority
          assignmentReminderLowPriority
          assignmentReminderHighPriority
          subcontractorCancel
          subcontractorUpdate
          subdispatcherProjectReminder
          smartSchedulerJobComplete
          operatorConnectionLost
          stopLoggingLowPriority
          stopLoggingHighPriority
          assignmentDeclined
          addedAsProjectCollaborator
        }
      }
    }
  }
`;

export const useBulkUpdateAccounts = () => {
  const [bulkUpdateAccounts] = useMutation(BULK_UPDATE_ACCOUNTS_MUTATION);
  return useCallback((accountIDs, input) => (
    bulkUpdateAccounts({
      variables: {
        accountIDs,
        input,
      }})
  ), [bulkUpdateAccounts]);
};

const COMPANY_ACCOUNT_PERMISSIONS_QUERY = gql`
  query CompanyAccountPermissions($companyID: ID!, $accountID: ID!) {
    companyAccountPermissions(companyID: $companyID, accountID: $accountID) {
      id
      key
    }
  }
`;

export const useCompanyAccountPermissions = (companyID, accountID) => {
  const { data, loading, error } = useQuery(COMPANY_ACCOUNT_PERMISSIONS_QUERY, {
    variables: { companyID, accountID },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyAccountPermissions
      : []
  ), [data, loading, error]);
};

const UPDATE_COMPANY_ACCOUNT_PERMISSIONS = gql`
  mutation UpdateCompanyAccountPermissions($companyID: ID!, $accountID: ID!, $input: PermissionsInput!) {
    updateCompanyAccountPermissions(companyID: $companyID, accountID: $accountID, input: $input) {
      id
      key
    }
  }
`;

export const useUpdateCompanyAccountPermissions = () => {
  const [updateCompanyAccountPermissions] = useMutation(UPDATE_COMPANY_ACCOUNT_PERMISSIONS);
  return useCallback((companyID, accountID, input) => (
    updateCompanyAccountPermissions({
      variables: {
        companyID,
        accountID,
        input,
      },
    })
  ), [updateCompanyAccountPermissions]);
};

