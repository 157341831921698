import React from 'react';
import {
  usePermissionsIDMap,
} from 'graphql/common';
import {
  ReadablePermissions,
} from 'utils/permissions';
import styles from './styles.module.scss';

const ChangedPermissions = ({
  oldPermissions,
  newPermissions,
}) => {
  const permissions = usePermissionsIDMap();
  // get added permissions
  const added = newPermissions.filter(np => !oldPermissions.includes(np));
  const removed = oldPermissions.filter(op => !newPermissions.includes(op));
  if (!!permissions && Object.keys(permissions).length > 0) {
    return (
      <div className={styles.changedPermissionsList}>
        {added.map(a => (
          <span key={`added-${permissions[a]}`} className="text-success">{ReadablePermissions[permissions[a]]} Added</span>
        ))}
        {removed.map(r => (
          <span key={`removed-${permissions[r]}`} className="text-danger">{ReadablePermissions[permissions[r]]} Removed</span>
        ))}
      </div>
    );
  }
  return null;
}

export default ChangedPermissions;
