import React from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const CompanySelect = ({ companies, setCompanyID }) => {
  if (companies.length >= 0) {
    return (
      <Form.Group className="py-1">
        <Form.Control
          as="select"
          id="companySelect"
          name="companyID"
          onChange={(e) => setCompanyID(e.target.value)}
        >
          {
            companies.map(c => (
              <option
                key={`company-${c.id}`}
                value={c.id}
              >
                {`${c.name}`}
              </option>
            ))
          }
        </Form.Control>
      </Form.Group>
    )
  }
  return (
    <>
      <Spinner animation="border" size="sm" />
      <span>Loading companies...</span>
    </>
  );
}

export default CompanySelect;
