import React, { useState } from 'react';
import _ from 'lodash';
import { useWriteError } from 'graphql/common';
import { CompanySelect } from 'components/common';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useCompany } from 'graphql/companies';
import Form from 'react-bootstrap/Form';
import { useAddSubcontractor } from 'graphql/subcontractors';

const AddExistingSubcontractorForm = ({
  companies,
  companyID,
  visible,
  setVisible,
  setStatus,
}) => {
  const writeError = useWriteError();
  const [subcontractorIDVal, setSubcontractorIDVal] = useState(0);
  const [availableVal, setAvailableValue] = useState(true);
  const [operatorCountVal, setOperatorCountValue] = useState(0);
  const subcontractor = useCompany(companyID);
  const addSubcontractor = useAddSubcontractor();
  if (!subcontractor) return null;
  const filteredCompanies = _.filter(companies, o => o.id !== companyID)
  const submitAddSubcontractor = () => {
    const input = {
      companyID: companyID,
      subcontractorID: subcontractorIDVal,
      available: availableVal,
      operatorCount: operatorCountVal,
    };
    addSubcontractor(companyID, input)
      .then(() => {
        setVisible(false);
      })
      .catch(err => {
        setVisible(false);
        writeError(err);
        setStatus(err.message);
      });
  };
  return (
    <Modal show={visible} onHide={() => setVisible(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Subcontractor to {subcontractor && subcontractor.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          Select Subcontractor
          <CompanySelect companies={filteredCompanies} setCompanyID={setSubcontractorIDVal}/>
          <Form.Group>
            <Form.Label>Operator Count</Form.Label>
            <Form.Control
              type="text"
              defaultValue={0}
              onChange={(e) => setOperatorCountValue(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              label="Available"
              type="checkbox"
              defaultChecked
              onChange={(e) => setAvailableValue(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Button
              variant="info"
              onClick={submitAddSubcontractor}
            >
              Add Subcontractor
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddExistingSubcontractorForm