import ApolloClient from 'apollo-boost';
import { APIGQLURL, APICOREGQLURL } from 'utils/env';

const clientStateMutationResolvers = {
  setError: (_, { errorMessage }, { cache }) => {
    const data = {
      error: {
        __typename: 'LocalError',
        errorMessage
      }
    };
    cache.writeData({ data });
    return null;
  },
  setLoggedInDetails: (_, { details }, { cache }) => {
    const data = {
      loggedInDetails: {
        ...details,
      },
    };
    cache.writeData({ ...cache.data, data });
  },
};

export const apolloClient = new ApolloClient ({
  uri: APIGQLURL,
  credentials: 'include',
  fetchOptions: {
    credentials: 'include'
  },
  onError: ({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      //graphQLErrors.map(({ message, locations, path }) => console.log(`[GQLError]: ${message} ${JSON.stringify(locations)} ${path}`));
    }
    if (networkError) {
      if (networkError.statusCode === 403 || networkError.statusCode === 401) {
        // Redirect to login
        // window.location = `/login`;
      }
    }
  },
  clientState: {
    defaults: {
      error: {
        __typename: 'LocalError',
        errorMessage: "",
      },
      loggedInDetails: {
        __typename: 'LocalLoggedInDetails',
        id: 0,
        firstName: '',
        lastName: '',
      }
    },
    resolvers: {
      Mutation: clientStateMutationResolvers,
    }
  }
});

export const coreApolloClient = new ApolloClient ({
  uri: APICOREGQLURL,
  credentials: 'include',
  fetchOptions: {
    credentials: 'include'
  },
});
