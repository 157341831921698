import React from 'react';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import {
  COMPANY_ADD_MUTATION,
} from 'graphql/companies';
import { useAddClient } from 'graphql/subcontractors';
import { defaultCompany } from 'tabs/Companies/Companies';

const CompanyDetailsSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Too short!').max(255, 'Too long!').required('Required'),
});

const AddClientFormRender = ({
  values,
  setValues,
  isSubmitting,
  submitForm,
  handleSubmit,
  errors,
  status,
  setVisible,
  companyName,
  ...rest
}) => (
  <>
    <Modal.Body>
      <FormikForm>
        <p>
        Add a new client-only company to {companyName}.<br/>
        This company will have limited access as a client-only company.
        </p>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            as={FormikField}
            id="name"
            name="name"
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <FormikField
            component={Form.Check}
            type="checkbox"
            id="status"
            name="status"
            label="Active"
            checked={values.status === 'ACTIVE'}
            onChange={() => setValues({ ...values, status: values.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' }) }
          />
        </Form.Group>
      </FormikForm>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => setVisible(false)}>Cancel</Button>
      <Button
        variant="primary"
        onClick={() => (handleSubmit())}
      >
        Create and Add Client
      </Button>
    </Modal.Footer>
  </>
);

const AddClientForm = ({
  companyID,
  companyName,
  setStatusMsg,
  visible,
  setVisible
}) => {

  const [addCompany] = useMutation(COMPANY_ADD_MUTATION, { refetchQueries: ['companies'] });
  const addClient = useAddClient()

  const onSubmit = (values, { setSubmitting, setStatus } ) => {
    setSubmitting(true);
      // Extract __typename and permissions because this was set when we initialized values
      const { __typename: _typeDiscard, permissions, ...input } = values;
      addCompany({
        variables: {
          input,
        },
      })
      .then((result) => addClient(companyID, result.data.addCompany.id))
      .then(() => {
        setVisible(false);
        setSubmitting(false);
      })
      .catch(err => {
        setVisible(false);
        setStatusMsg(err.message)
      });
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...defaultCompany,
        tier: 'Client Only',
      }}
      validationSchema={CompanyDetailsSchema}
      onSubmit={onSubmit}
      children={props =>
        <AddClientFormRender
          setVisible={setVisible}
          companyName={companyName}
          {...props}
        />
      }
    />
  );
};

export default AddClientForm;

