import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import {
  useAccountByPhoneNumber,
  useAddCompanyAccount,
} from 'graphql/accounts';
import {
  useCompany,
} from 'graphql/companies';

const phoneFuzzRegex = /^[\d+]{1,12}$/
const ExistingAccountSearchSchema = Yup.object().shape({
  phoneNumber: Yup.string().matches(phoneFuzzRegex, 'Please enter a valid search term, using only numbers. The country code is allowed (+1, etc)').required('Required'),
});

const phoneNumberSearch = (phoneNumber, setValidatedPhoneNumber, setError) => {
  ExistingAccountSearchSchema.validate({
    phoneNumber,
  })
    .then(() => {
      setValidatedPhoneNumber(phoneNumber);
    })
    .catch(e => setError(e.message))
}

const ExistingAccountDetail = ({
  phoneNumber,
  companyID,
  companyName,
  setExistingAccountModalVisible,
  setValidatedPhoneNumber,
}) => {
  const account = useAccountByPhoneNumber(phoneNumber);
  const addCompanyAccount = useAddCompanyAccount();
  if (!account) {
    return (
      <Card className="my-2">
        <Card.Body>
          <Card.Text>
            No accounts found
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        accountID: account && account.id ? account.id : 0,
        companyID,
      }}
      // validationSchema={AccountDetailsSchema}
      onSubmit={(values, { setSubmitting, setStatus } ) => {
        setSubmitting(true);
        addCompanyAccount(values.accountID, values.companyID)
          .then(() => {
            setSubmitting(false);
            setValidatedPhoneNumber('');
            setExistingAccountModalVisible(false);
          })
          .catch(err => setStatus({ error: err.message }));
      }}
    >
      {({ values, errors, status, handleSubmit, isSubmitting }) => (
        <Card className="my-2">
          <Card.Body>
            <Card.Title>
              {`${account.firstName} ${account.lastName}`}
            </Card.Title>
            <Card.Text>{account.phoneNumber}</Card.Text>
            <Button
              variant="success"
              onClick={() => !isSubmitting && handleSubmit()}
            >
              + Add this account to {companyName}
            </Button>
          </Card.Body>
          {!!status && !!status.error && <Alert variant="danger">{status.error}</Alert>}
        </Card>
      )}
    </Formik>
  );
}

const ExistingAccountModal = ({
  companyID,
  existingAccountModalVisible,
  setExistingAccountModalVisible,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validatedPhoneNumber, setValidatedPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const company = useCompany(companyID);
  const companyName = company && company.name;

  return (
    <Modal show={existingAccountModalVisible} onHide={() => setExistingAccountModalVisible(false)} >
      <Modal.Header closeButton>
        <Modal.Title>Account Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={e => {
            phoneNumberSearch(phoneNumber, setValidatedPhoneNumber, setError);
            e.preventDefault()
          }}
        >
          <Form.Group>
            <Form.Label>Search for an account by phone number</Form.Label>
            <Form.Control
              as="input"
              id="phoneNumber"
              name="phoneNumber"
              isInvalid={!!error}
              onChange={e => setPhoneNumber(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            <Form.Text className="text-muted">Provide up to 12 digits, you may include country code</Form.Text>
          </Form.Group>
          <Button
            onClick={() => phoneNumberSearch(phoneNumber, setValidatedPhoneNumber, setError)}
          >
            Search
          </Button>
        </Form>
        { !!validatedPhoneNumber &&
          <ExistingAccountDetail
            phoneNumber={validatedPhoneNumber}
            companyID={companyID}
            companyName={companyName}
            {...{
              setExistingAccountModalVisible,
              setValidatedPhoneNumber
            }}
          />
        }
      </Modal.Body>
    </Modal>
  );
}

export default ExistingAccountModal;
