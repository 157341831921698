import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {
  FieldArray,
  Field as FormikField,
} from 'formik';
import {
  useCompanyGroupsForCompany,
} from 'graphql/company_groups';
import { actions } from 'utils/form';

const AccountBasicForm = ({
  action,
  companyID,
  values,
  setValues,
  errors,
}) => {
  const companyGroups = useCompanyGroupsForCompany(companyID);
  if (action === actions.BULKUPDATE) {
    return null;
  }
  return (
    <Modal.Body>
      <h5>Basic Details</h5>
      <Form.Group>
        <Form.Label>First Name</Form.Label>
        <Form.Control
          as={FormikField}
          id="firstName"
          name="firstName"
          isInvalid={!!errors.firstName}
        />
        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          as={FormikField}
          id="lastName"
          name="lastName"
          isInvalid={!!errors.lastName}
        />
        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          as={FormikField}
          id="phoneNumber"
          name="phoneNumber"
          isInvalid={!!errors.phoneNumber}
        />
        <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          as={FormikField}
          id="email"
          name="email"
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <FormikField
          component={Form.Check}
          type="checkbox"
          id="disabled"
          name="disabled"
          label="Disabled"
          checked={values.disabled}
          onChange={() => setValues({ ...values, disabled: !values.disabled }) }
        />
      </Form.Group>
      <h5>Company Groups</h5>
      <Form.Group>
        <FieldArray
          name="companyGroups"
          render={arrayHelpers => (
            <>
              {!!companyGroups && companyGroups.sort((a, b) => a.role.roleRank - b.role.roleRank)
              .map(cg => (
                <Form.Check
                  type="checkbox"
                  id={cg.id}
                  key={cg.id}
                  value={cg.id}
                  checked={values.companyGroups.includes(cg.id)}
                  onChange={e => {
                    if (e.target.checked) arrayHelpers.push(cg.id);
                    else {
                      const idx = values.companyGroups.indexOf(cg.id);
                      arrayHelpers.remove(idx);
                    }
                  }}
                  label={cg.name}
                />
              ))}
            </>
          )}
        />
      </Form.Group>
    </Modal.Body>
  );
};

export default AccountBasicForm;
