import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styles from './styles.module.scss';
import { useWriteError } from 'graphql/common';
import { useClientsByCompanyID, useUpdateCompanyClientByID } from 'graphql/subcontractors';

export const permissions = {
  PROJECT: 'PROJECT',
  ON_GOING: 'ON_GOING',
  DAY: 'HOUR',
};

const yesNo = {
  YES: true,
  NO: false,
}

const PermissionSelect = ({ permValue, setPermValue, showHaulerValue, setHaulerValue }) => (
  <>
    <Row>
      <Col>Permission: </Col>
      <Col>
        <select
          id="permission"
          className={styles.permissionSelect}
          value={permValue}
          onChange={e => setPermValue(e.target.value)}
        >
          {Object.keys(permissions).map(l => (
            <option
              key={`request-${permissions[l]}`}
              value={permissions[l]}
            >
              {l}
            </option>
          ))}
        </select>
      </Col>
    </Row> 
    <Row>
      <Col>Show Hauler Rates: </Col>
      <Col>
        <select
          id="permission-haulerrate"
          className={styles.permissionSelect}
          value={showHaulerValue}
          onChange={e => setHaulerValue(e.target.value)}
        >
          {Object.keys(yesNo).map(l => (
            <option
              key={`hauler-${yesNo[l]}`}
              value={yesNo[l]}
            >
              {l}
            </option>
          ))}
        </select>
      </Col>
    </Row>
  </>
);

const ClientDetails = ({
  clientCompany,
  client,
  companyID,
  visible,
  setVisible,
  setStatusMsg,
}) => {
  const [permissionValue, setPermissionValue] = useState();
  const [showHaulerValue, setHaulerValue] = useState();
  const updateClient = useUpdateCompanyClientByID();
  const writeError = useWriteError();
    
  useEffect(() => {
    if (client && client.permissions.hotDispatch) {
      setPermissionValue(client.permissions.hotDispatch.permission);
      setHaulerValue(client.permissions.hotDispatch.showHaulerRate);
    }
    
  },[client, setPermissionValue, setHaulerValue]);

  const submitUpdateClient = () => {
    let perm = permissionValue; 
    if (!perm) {
      perm = client.permissions.hotDispatch.permission
    }

    let showHauler = showHaulerValue
    if (typeof showHauler === 'undefined') {
      showHauler = client.permissions.hotDispatch.showHaulerRate
    } else {
      showHauler = (showHaulerValue === 'true') || (showHaulerValue === true)
    } 
    const input = {
      id: client.id,
      permissionType: perm,
      showHaulerRate: showHauler,
    };
    updateClient(companyID, input)
      .then(() => {
        setVisible(false);
      })
      .catch(err => {
        writeError(err);
        setStatusMsg(err.message);
      });
  };
  if (!client) return null;
  return (
    <Modal show={visible} onHide={() => setVisible(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{clientCompany && clientCompany.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
            <PermissionSelect 
              permValue={permissionValue} 
              setPermValue={setPermissionValue} 
              showHaulerValue={showHaulerValue}
              setHaulerValue={setHaulerValue}
            />
          <div className={styles.bottomSpace}/>
          <Button onClick={submitUpdateClient}>Update</Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export const ClientRow = ({
  id,
  client,
  clientCompany,
  setModalVisible,
  setModalClientCompany,
  setModalClient,
}) => {  
  const openClientModal = () => {
    setModalClientCompany(clientCompany);
    setModalClient(client);    
    setModalVisible(true);
  }
  const findKeyFromValue = (obj, val) => Object.keys(obj).find(key => obj[key] === val);
  const permissionVal = client.permissions.hotDispatch ? findKeyFromValue(permissions, client.permissions.hotDispatch.permission) : '';
  const showHaulerRateVal = client.permissions.hotDispatch ? findKeyFromValue(yesNo, client.permissions.hotDispatch.showHaulerRate): '';
    
  return (
    <tr>
      <td>{clientCompany.id}</td>
      <td>{clientCompany.name}</td>
      <td>{permissionVal}</td>
      <td>{showHaulerRateVal}</td>
      <td>
        <Button variant="info" onClick={() => {
          openClientModal();
        }}>Manage</Button>
      </td>
    </tr>
  );
};

export const ClientsTable = ({
  companyID,
  clients,
  setStatusMsg,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalClientCompany, setModalClientCompany] = useState();
  const [modalClient, setModalClient] = useState();
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Company ID</th>
            <th>Name</th>
            <th>Permission Given</th>
            <th>Show Hauler Rate</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {!!clients &&
            clients.map(s => (
              <ClientRow
                key={s.id}
                id={s.id}
                clientCompany={s.client}
                client={s}
                setModalVisible={setModalVisible}
                setModalClientCompany={setModalClientCompany}
                setModalClient={setModalClient}
              />
            ))   
          }
        </tbody>
      </Table>
      <ClientDetails
        visible={modalVisible}
        setVisible={setModalVisible}
        clientCompany={modalClientCompany}
        client={modalClient}
        companyID={companyID}
        setStatusMsg={setStatusMsg}
      />
    </>
  );
};

export const ClientList = ({
    companyID,
    setStatusMsg,
  }) => {
    const clients = useClientsByCompanyID(companyID);
    return (
      <>
        Clients
        <div/>
        <ClientsTable
          clients= {clients}
          companyID={companyID}
          setStatusMsg={setStatusMsg}
        />
      </>
    );
  };
