import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import CompanySelectAutocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { CenterContainer } from 'App';
import {
  useImpersonate,
  useStopImpersonating,
  useAccountsByCompanyGroup,
  useImpersonatingAs,
} from 'graphql/impersonation';
import {
  useCompanies,
  useAccountsByCompany,
} from 'graphql/common';
import {
  useCompanyGroupsForCompany,
} from 'graphql/company_groups';

const isImpersonating = (account) => (
  !!account && !!account.id
)
let externalCompanyID = '';
const getImpersonationDetails = (account) => {
  return (
    <Card className="my-2">
      { account && account.id
        ? (
          <Card.Body>
            <Card.Text>
              ID: {account.id}
            </Card.Text>
            <Card.Text>
              Name: {`${account.firstName} ${account.lastName}`}
            </Card.Text>
          </Card.Body>
        )
        : (
          <Card.Body>None</Card.Body>
        )
      }
    </Card>
  );
}

const CompanyGroupSelect = ({ companyID, values, setValues }) => {
  if (externalCompanyID !== ''){
    companyID = externalCompanyID;
  }
  const companyGroups = useCompanyGroupsForCompany(companyID).concat({ name: '' });
  return (
    <Form.Control
      as={FormikField}
      component="select"
      id="companyGroupSelect"
      name="companyGroup"
    >
      {
        companyGroups.map(t => (
          <option
            key={`companyGroup-${t.name}`}
            value={t.name}
          >
            {t.name || 'No Role'}
          </option>
        ))
      }
    </Form.Control>
  )
};

// values from FormikBag
const CompanySelect = ({ companies, ...rest }) => {
  if (companies.length >= 0) {
    let companyID = companies[0].id;
    if (!!rest.values.companyID) {
      companyID = rest.values.companyID;
    }
    return (
        <Form.Group className="py-1">
          <Form.Label>Select Company</Form.Label>
          <InitCompanySelect companies={companies} companyID={companyID} />
          <Form.Label>Select Role</Form.Label>
          <CompanyGroupSelect values={undefined} setValues={undefined} companyID={companyID} {...rest} />
          <Form.Label>Select Account</Form.Label>
          <CompanyAccountSelect values={undefined} setValues={undefined} companyID={companyID} {...rest} />
        </Form.Group>
    )
  }
  return (
    <>
      <Spinner animation="border" size="sm" />
      <span>Loading companies...</span>
    </>
  );
}

const InitCompanySelect = ({companies,companyID, ...rest}) => {
  return (
    <CompanySelectAutocomplete
      disablePortal
      id="combo-box-demo"
      getOptionLabel={(option) => option.name}
      options={companies}
      onChange={(e, value) => {
        if (value!=null) {
          companyID = value.id;
          externalCompanyID=value.id;
        }
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Select company" />}
    />
  )
}
// setValues from formikbag, we need this to imperatively set the selected adminID as a default
const CompanyAccountSelect = ({ companyID, values, setValues }) => {
  if (externalCompanyID !== '') {
    companyID=externalCompanyID
  }
  const companyGroup = values.companyGroup;
  const accounts = useAccountsByCompanyGroup(companyID, companyGroup);
  const companyAccounts = useAccountsByCompany(companyID);

  if (!!companyAccounts) {
    // get list of all IDs, to check if this dropdown was rerendered with a different company's admins
    const validIDs = accounts.reduce((acc, v) => ([...acc, v.id]), [])
    if (validIDs.length === 0) {
      // No admins in this company (??)
      return (<div>No accounts in this company group!</div>)
    } else if (_.indexOf(validIDs, values.companyAdminID) < 0 && accounts.length > 0) {
      setValues({ ...values, companyAdminID: accounts[0].id });
    }
    return (
      <Form.Control
        as={FormikField}
        component="select"
        id="companyAdminSelect"
        name="companyAdminID"
      >
        {
          accounts.map(a => (
            <option
              key={`account-${a.id}`}
              value={a.id}
            >
              {`
                ${a.firstName} ${a.lastName} -
                ${a.id}
              `}
            </option>
          ))
        }
      </Form.Control>
    )
  }
  return (
    <>
      <Spinner animation="border" size="sm" />
      <span>Loading admins...</span>
    </>
  );
}

const ImpersonationModal = ({ visible, setVisible }) => {
  const companies = useCompanies(true);
  const impersonate = useImpersonate();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        impersonationID: '',
        companyID: '',
        accountType: 'Administrator',
        companyAdminID: '',
      }}
      onSubmit={({ impersonationID, companyAdminID }, { setSubmitting }) => {
        let id = impersonationID;
        if (!impersonationID && !!companyAdminID) {
          id = companyAdminID;
        }
        setSubmitting(true);
        impersonate(id);
        setVisible(false);
        setSubmitting(false);
      }}
      render={({ handleSubmit, ...rest }) => (
        <Modal
          size="lg"
          centered
          show={visible}
          onHide={() => setVisible(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Impersonate as...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Select a company and an account to impersonate as
            <FormikForm>
              <CompanySelect companies={companies} {...rest} />

              Or enter the ID if you already know it
              <Form.Group className="py-1">
                <Form.Control
                  as={FormikField}
                  id="impersonationID"
                  name="impersonationID"
                  placeholder="Impersonation Account ID"
                />
              </Form.Group>
              <Button onClick={handleSubmit}>Impersonate</Button>
            </FormikForm>
          </Modal.Body>
        </Modal>
      )}
    />
  );
};

export const ConfirmDialog = ({ confirmText, visible, setVisible, callback }) => {
  return (
    <Modal
      size="lg"
      centered
      show={visible}
      onHide={() => setVisible(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confirmText}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => {callback(); setVisible(false)}}>Confirm</Button>
        <Button variant="danger" onClick={() => setVisible(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export const Impersonation = () => {
  const [impersonationModalVisible, setImpersonationModalVisible] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const impersonatingAs = useImpersonatingAs();
  const stopImpersonating = useStopImpersonating();

  return (
    <CenterContainer>
      <Card className="my-3">
        <Card.Body>
          <>
            <Card.Subtitle>Impersonating as:</Card.Subtitle>
            {getImpersonationDetails(impersonatingAs)}
          </>
          <ButtonGroup>
            <Button
              variant="primary"
              onClick={() => setImpersonationModalVisible(!impersonationModalVisible)}
            >
              Impersonate
            </Button>
            <Button
              disabled={!isImpersonating(impersonatingAs)}
              variant="danger"
              onClick={() => setConfirmDialogVisible(!confirmDialogVisible)}
            >
              Stop Impersonation
            </Button>
          </ButtonGroup>
        </Card.Body>
      </Card>
      <ImpersonationModal
        visible={impersonationModalVisible}
        setVisible={setImpersonationModalVisible}
      />
      <ConfirmDialog
        confirmText="Are you sure you want to stop impersonating?"
        visible={confirmDialogVisible}
        setVisible={setConfirmDialogVisible}
        callback={stopImpersonating}
      />
    </CenterContainer>
  )
};
