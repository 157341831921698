const Permissions = {
  projects: [
    'CreateProjects',
    'ViewOwnProjects',
    'EditOwnProjects',
    'RemoveOwnProjects',
    'CopyOwnProjects',
    'ViewAllProjects',
    'EditAllProjects',
    'RemoveAllProjects',
    'CopyAllProjects',
    'DownloadProjects',
    'ShareProjects',
    'ViewProjectsAsForeman',
    'ViewProjectsAsTagged',
    'ViewProjectsAsClient',
    'ViewProjectsAsCompanyTagged',
    'ViewAssignableHires',
  ],
  live: [
    'ViewAllLiveProjects',
    'ViewOwnLiveProjects',
    'ViewLiveProjectsAsForeman',
    'ViewLiveProjectsAsTagged',
    'ViewLiveProjectsAsClient',
    'ViewLiveProjectsAsCompanyTagged',
  ],
  email: [
    'SendEmails', // This permission is for sharing projects via Email on dispatch
  ],
  companies: [
    'AddCompany',
    'EditCompany',
  ],
  hires: [
    'ViewInternalHires',
    'CreateInternalHires',
    'RemoveInternalHires',
    'EditInternalHires',
    'AddAdmin',
    'RemoveAdmin',
    'ViewSubcontractors',
    'CreateSubcontractors',
    'EditSubcontractors',
    'RemoveSubcontractors',
  ],
  clients: [
    'ViewClients',
    'CreateClients',
    'EditClients',
    'RemoveClients',
  ],
  assignments: [
    'AddAssignment',
    'EditAssignment',
    'CancelAssignment',
    'SendAssignment',
    'ReassignAssignment',
  ],
  targets: [
    'CreateTargets',
    'FinishTargets',
    'RemoveTargets',
    'EditAllTargets',
    'EditOwnTargets',
    'ViewOwnTargets',
    'ViewAllTargets',
    'ViewTargetsAsTagged',
    'ViewTargetsAsClient',
    'ViewTargetsAsCompanyTagged',
  ],
  requests: [
    'AddSubdispatchRequests',
    'ViewSubdispatchRequests',
    'AcceptDeclineRequests',
    'ViewAllInternalRequests',
    'ViewOwnInternalRequests',
    'CreateInternalRequest',
    'CopyInternalRequests',
    'CancelInternalRequests',
    'ReviseInternalRequests',
    'CreateInternalNote',
    'ReadInternalNote',
  ],
  sites: [
    'CreateSites',
    'ViewAllSites',
    'ViewOwnSites',
    'EditAllSites',
    'EditOwnSites',
  ],
  receipts: [
    'ViewOwnReceipts',
    'ViewAllReceipts',

    'ViewAllHistory',
    'ViewHistoryAsForeman',
    'ViewHistoryAsTagged',
    'ViewHistoryAsClient',
    'ViewHistoryAsCompanyTagged',

    'SignOffTickets', // TODO may not be needed
    'EditAllTickets',
    'EditOwnTickets',
    'EditTicketsAsForeman',
    'EditTicketsAsTagged',
    'EditTicketsAsClient',
    'EditTicketsAsCompanyTagged',

    'AddDigitalSignoff',
    'EditDigitalSignoff',
    'ViewDigitalSignoff',
    'DeleteDigitalSignoff',
    'AddDriverTicketSignoff',
    'AddTicket',
    'EditTicket', // TODO may not be needed when we switch to EditAll/EditOwn perms
    'ViewTicket',
    'DeleteTicket',
    'DownloadTicketImage',
    'DownloadTicketsCSV',
  ],
  insights: [
    'ViewInsightsDash',
    'ViewWeeklyActivityDash',
    'ViewCompanyComplianceDash',
    'ViewCycleTimeDash',
    'ViewSiteGeofenceDash',
    'ViewAlertsDash',
    'ViewBillingDash',
    'ViewHirePerfDash',
    'ViewInternalSummaryDash',
    'ViewProgressDash',
    'ViewScheduleSummaryDash',
    'ViewDailyHiresDash',
    'ViewHirePerfAndSafetyDash',
    'ViewSafetyDash',
    'ViewSiteTrafficDash',
    'ViewSiteActivityBeta',
    'ViewDriverLocationsDash',
    'ViewTicketToTicketDash',
    'ViewProjectSummaryDash',
    'ViewDriverSummaryDash',
    'ViewUtilizationDash',
    'ViewPaySummaryDash',
    'ViewDriverToPaverDash',
    'ViewDriverTripTimesDash',
  ],
  premiumFeatures: [
    'OperatorAutoReplySms',
    'AutoAssignmentTermination',
    'SmartScheduler',
    'AddDigitalSignoff', // This should control the DSO premium feature
    'AddDriverTicketSignoff',
    'CanSelfLog',
    'ViewEstimatedDuration',
    'ViewHoursScheduled',
    'ViewLiveCycleInsights',
    'ViewDriverRoute',
    'MoveTickets',
    'ForwardTicketsOnUpload',
    'ForwardTicketsOnSignoff',
    'CanRetroDispatch',
    'CanEndDriverLog',
    'ViewAggregatedTimesheets',
    'DisableDriverDeclineOption', // TODO: This should be moved to a seperate features section
    'RequireExternalTargets',
    'DistanceTracking',
    'TimesheetDispatch',
    'TimesheetDispatchWithForeman',
  ],
  billingAndInvoice: [
    'CreateSalesInvoice'
  ],
};

export const ReadablePermissions = {
  CreateProjects: 'Create Projects',
  ViewOwnProjects: 'View Own Projects',
  EditOwnProjects: 'Edit Own Projects',
  RemoveOwnProjects: 'Remove Own Projects',
  CopyOwnProjects: 'Copy Own Projects',
  ViewAllProjects: 'View All Projects',
  EditAllProjects: 'Edit All Projects',
  RemoveAllProjects: 'Remove All Projects',
  CopyAllProjects: 'Copy All Projects',
  SendEmails: 'Send Emails',
  DownloadProjects: 'Download Projects as CSV',
  ShareProjects: 'Share Projects',
  ViewProjectsAsForeman: 'View Projects as Foreman',
  ViewProjectsAsClient: 'View Projects as Client',
  ViewProjectsAsTagged: 'View Projects as Tagged',
  ViewProjectsAsCompanyTagged: 'View Projects as Company Tagged',
  ViewAssignableHires: 'View Assignable Hires',
  CanRetroDispatch: 'Can Retroactively Dispatch',

  ViewAllLiveProjects: 'View All Live Projects',
  ViewOwnLiveProjects: 'View my Live Projects',
  ViewLiveProjectsAsForeman: 'View Live Projects as Foreman',
  ViewLiveProjectsAsTagged: 'View Live Projects as Tagged',
  ViewLiveProjectsAsClient: 'View Live Projects as Client',
  ViewLiveProjectsAsCompanyTagged: 'View Live Projects as Company Tagged',

  ViewClients: 'View Clients',
  CreateClients: 'Create Clients',
  EditClients: 'Edit Clients',
  RemoveClients: 'Remove Clients',

  CreateTargets: 'Create Targets',
  FinishTargets: 'Finish Targets',
  RemoveTargets: 'Remove Targets',
  EditAllTargets: 'Edit All Targets',
  EditOwnTargets: 'Edit Own Targets',
  ViewOwnTargets: 'View Own Targets',
  ViewAllTargets: 'View All Targets',
  ViewTargetsAsTagged: 'View Targets as Tagged',
  ViewTargetsAsClient: 'View Targets as Client',
  ViewTargetsAsCompanyTagged: 'View Targets As Company Tagged',

  CreateSites: 'Create Sites',
  ViewAllSites: 'View All Sites',
  ViewOwnSites: 'View Own Sites',
  EditAllSites: 'Edit All Sites',
  EditOwnSites: 'Edit Own Sites',
  ViewDriverRoute: 'View Driver Route',

  ViewAllHistory: 'View All History',
  ViewHistoryAsForeman: 'View History as Foreman',
  ViewHistoryAsTagged: 'View History as Tagged',
  ViewHistoryAsClient: 'View History as Client',
  ViewHistoryAsCompanyTagged: 'View History as Company Tagged',

  AddCompany: 'Add Company', // TODO may not be needed
  EditCompany: 'Edit Company', // TODO may not be needed

  ViewInternalHires: 'View Internal Hires',
  CreateInternalHires: 'Add Internal Hire',
  RemoveInternalHires: 'Remove Internal Hire',
  EditInternalHires: 'Edit Internal Hire',

  ViewSubcontractors: 'View Subcontractors',
  CreateSubcontractors: 'Create Subcontractors',
  EditSubcontractors: 'Edit Subcontractors',
  RemoveSubcontractors: 'Remove Subcontractors',

  AddAdmin: 'Add Admin',
  RemoveAdmin: 'Remove Admin',
  AddAssignment: 'Add Assignment',
  EditAssignment: 'Edit Assignment',
  CancelAssignment: 'Cancel Assignment',
  SendAssignment: 'Send Assignment',
  ReassignAssignment: 'Reassign Assignment',

  ViewAllInternalRequests: 'View All Internal Requests',
  ViewOwnInternalRequests: 'View Own Internal Requests',
  AddSubdispatchRequests: 'Create Subdispatch Request',
  ViewSubdispatchRequests: 'View Requests',
  AcceptDeclineRequests: 'Accept/Decline Requests',
  CreateInternalRequest: 'Create Internal Request',
  CopyInternalRequests: 'Copy Internal Request',
  CancelInternalRequests: 'Cancel Internal Request',
  ReviseInternalRequests: 'Revise Internal Request',
  CreateInternalNote: 'Create Internal Note',
  ReadInternalNote: 'Read Internal Note',

  SignOffTickets: 'Sign off Tickets',
  EditAllTickets: 'Edit All Tickets',
  EditOwnTickets: 'Edit Own Tickets',
  EditTicketsAsForeman: 'Edit Tickets As Foreman',
  EditTicketsAsTagged: 'Edit Tickets As Tagged',
  EditTicketsAsClient: 'Edit Tickets As Client',
  EditTicketsAsCompanyTagged: 'Edit Tickets As Company Tagged',

  EditCompanySettings: 'Edit Company Settings',
  ViewCompanySettings: 'View Company Settings',
  ViewDOTFields: 'View DOT Fields',
  ExportDOTFields: 'Export DOT Fields',
  FindTicketInfo: 'Find Ticket Info',
  EditRoadwayTemperature: 'Edit Roadway Temperature',
  EditPlantTemperature: 'Edit Plant Temperature',
  EditWasteInfo: 'Edit Waste Info',
  AcceptRejectLoad: 'Accept/Reject Load',

  DigitalSignoff: 'Digital Signoff',
  AddDigitalSignoff: 'Add Digital Signoff',
  EditDigitalSignoff: 'Edit Digital Signoff',
  ViewDigitalSignoff: 'View Digital Signoff',
  DeleteDigitalSignoff: 'Delete Digital Signoff',
  AddDriverTicketSignoff: 'Add Driver Ticket Signoff',

  AddTicket: 'Add Ticket',
  EditTicket: 'Edit Ticket', // TODO Might not need this if we use EditAll/EditOwn perms above
  ViewTicket: 'View Ticket',
  DeleteTicket: 'Delete Ticket',
  DownloadTicketImage: 'Download Ticket image',
  DownloadTicketsCSV: 'Download Tickets CSV',
  MoveTickets: 'Can Move Tickets',
  ForwardTicketsOnUpload: 'Forward Tickets On Upload',
  ForwardTicketsOnSignoff: 'Forward Tickets On Signoff',
  ViewAggregatedTimesheets: 'View Aggregated Timesheets',

  ViewOwnReceipts: 'View Own Receipts',
  ViewAllReceipts: 'View All Receipts',
  ViewInsightsDash: 'View Insights Tab',
  ViewWeeklyActivityDash: 'View Weekly Activity Dash',
  ViewCompanyComplianceDash: 'View Compliance Dash',
  ViewCycleTimeDash: 'View Cycle Time Dash',
  ViewSiteGeofenceDash: 'View Site Activity Dash',
  ViewAlertsDash: 'View Alerts Dash (Experimental)',
  ViewBillingDash: 'View Billing Dash (Experimental)',
  ViewHirePerfDash: 'View Hire Performance Dash',
  ViewInternalSummaryDash: 'View KPIs Dash (Beta)',
  ViewProgressDash: 'View Progress Dash',
  ViewScheduleSummaryDash: 'View Schedule Summary Dash (Experimental)',
  ViewDailyHiresDash: 'View Daily Hires Dash',
  ViewHirePerfAndSafetyDash: 'View Hire Perf and Safety Dash',
  ViewSafetyDash: 'View Safety Dash',
  ViewSiteTrafficDash: 'View Site Traffic Dash',
  ViewSiteActivityBeta: 'View Site Activty BETA Dash',
  ViewDriverLocationsDash: 'View Driver Locations Dash',
  ViewTicketToTicketDash: 'View Ticket-to-Ticket Dash',
  ViewProjectSummaryDash: 'View Project Summary Dash',
  ViewDriverSummaryDash: 'View Driver Summary Dash',
  ViewUtilizationDash: 'View Utilization Dash',
  ViewPaySummaryDash: 'View Pay Summary Dash',
  ViewDriverToPaverDash: 'View Driver to Paver Dash',
  ViewDriverTripTimesDash: 'View Driver Trip Times Dash',

  OperatorAutoReplySms: 'Text-To-Accept',
  AutoAssignmentTermination: 'Auto Long-Running Assignment Termination',
  SmartScheduler: 'Smart Scheduler',
  CanSelfLog: 'Can Self Log',
  ViewEstimatedDuration: 'Estimated Duration',
  ViewHoursScheduled: 'Hours Scheduled',
  ViewLiveCycleInsights: 'Live Cycle Insights',

  // Billing and Invoice
  CreateSalesInvoice: 'Create Sales Invoice',
  CanEndDriverLog: "Can End Driver's Log",

  DisableDriverDeclineOption: 'Disable Driver Decline Option',
  RequireExternalTargets: 'Require External Targets',

  DistanceTracking: 'Track the distance of assignment routes',
  TimesheetDispatch: 'In-Field Dispatch via Timesheet',
  TimesheetDispatchWithForeman: 'In-Field Dispatch via Timesheet with Foreman',
};

// This is the list of permissions that can be set on CompanyGroups
// Groups contain permissions for everything except for the text-to-accept/autoterminate ones
// TODO If the permission key is not specified here
// but it is set on the DB for the object,
// the permission relation should be cleared when we update the object
// just to clean up mistakes that were made when adding permissions
export const CompanyGroupPermissions = {
  email: null,
  premiumFeatures: null
};

// This is the list of permissions that can be set on CompanyAccounts
export const CompanyAccountPermissions = {
  email: Permissions.email,
  insights: Permissions.insights,
  billingAndInvoice: Permissions.billingAndInvoice,
};

// Company permissions include only the text-to-accept and autoterminate permissions
// All other permissions should not be set on the company level
// TODO in the future Insights may be enabled on a company wide basis
export const CompanyPermissions = {
  premiumFeatures: Permissions.premiumFeatures,
};

export const PermissionsGroupLabel = {
  projects: 'Projects',
  live: 'Live',
  email: 'Emails',
  targets: 'Targets',
  sites: 'Sites',
  history: 'History',
  tickets: 'Tickets',
  companies: 'Companies',
  hires: 'Hires',
  assignments: 'Assignments',
  requests: 'Requests',
  receipts: 'Receipts/History',
  insights: 'Insights Dashboards',
  premiumFeatures: 'Premium Features',
  billingAndInvoice: 'Billing and Invoice'
}

// This map is used to control visibility of the permissions display
// We still have to receive/send all permissionIDs attached to the
// CompanyGroup/CompanyAccount/Company
// since updating permissions means sending the full state of the permissions
// e.g.
//    [1 2 3 4 5], but we only display 1 2 3
//    Want to remove 1 2
//    If we only request what we want to display,
//    we can't know whether 4, 5 were set on the object
//    And when we send back [3] we'll actually be removing 1 2 4 and 5
export const PermissionEditable = {
  ViewInsightsDash: true,
  ViewWeeklyActivityDash: true,
  ViewCompanyComplianceDash: true,
  ViewCycleTimeDash: true,
  ViewSiteGeofenceDash: true,
  ViewAlertsDash: true,
  ViewBillingDash: true,
  ViewHirePerfDash: true,
  ViewInternalSummaryDash: true,
  ViewProgressDash: true,
  ViewScheduleSummaryDash: true,
  ViewDailyHiresDash: true,
  ViewHirePerfAndSafetyDash: true,
  ViewSafetyDash: true,
  ViewSiteTrafficDash: true,
  ViewSiteActivityBeta: true,
  ViewDriverLocationsDash: true,
  ViewTicketToTicketDash: true,
  ViewProjectSummaryDash: true,
  ViewDriverSummaryDash: true,
  ViewUtilizationDash: true,
  ViewPaySummaryDash: true,
  ViewDriverToPaverDash: true,
  OperatorAutoReplySms: true,
  AutoAssignmentTermination: true,
  SmartScheduler: true,
  SendEmails: true,
  AddDigitalSignoff: true,
  AddDriverTicketSignoff: true,
  CanSelfLog: true,
  ViewEstimatedDuration: true,
  ViewHoursScheduled: true,
  ViewLiveCycleInsights: true,
  CreateSalesInvoice: true,
  ViewDriverRoute: true,
  CanRetroDispatch: true,
  MoveTickets: true,
  CanEndDriverLog: true,
  ForwardTicketsOnUpload: true,
  ForwardTicketsOnSignoff: true,
  ViewAggregatedTimesheets: true,
  ViewDriverTripTimesDash: true,
  DisableDriverDeclineOption: true,
  RequireExternalTargets: true,
  DistanceTracking: true,
  TimesheetDispatch: true,
  TimesheetDispatchWithForeman: true,
};

export const permissionIsEditable = (p) => PermissionEditable[p];
