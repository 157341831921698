import React, { useState } from 'react';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import { CompanySelect } from 'components/common';
import Button from 'react-bootstrap/Button';
import { Formik, Form as FormikForm } from 'formik';
import { useAddClient } from 'graphql/subcontractors';
import { defaultCompany } from 'tabs/Companies/Companies';

const AddExistingClientFormRender = ({
  values,
  setValues,
  isSubmitting,
  submitForm,
  handleSubmit,
  errors,
  status,
  setStatusMsg,
  setVisible,
  companyName,
  companies,
  setSelectedClientIDVal,
  ...rest
}) => (
  <>
    <Modal.Body>
      <FormikForm>
        <p>
        Add an existing company as a client to {companyName}.<br/>
        This client can then be tagged as a client on projects.
        </p>
          Select Company 
          <CompanySelect companies={companies} setCompanyID={setSelectedClientIDVal}/>
      </FormikForm>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => setVisible(false)}>Cancel</Button>
      <Button
        variant="primary"
        onClick={handleSubmit}
      >
        Add Client 
      </Button>
    </Modal.Footer>
  </>
);

const AddExistingClientForm = ({
  companies,
  companyID,
  companyName,
  setStatusMsg,
  visible,
  setVisible
}) => {

  const addClient = useAddClient()
  const [selectedClientIDVal, setSelectedClientIDVal] = useState(0);
  companies = _.filter(companies, o => o.id !== companyID);

  const onSubmit = (values, { setSubmitting, setStatus } ) => {
    setSubmitting(true);
      addClient(companyID, selectedClientIDVal)
      .then(() => {
        setVisible(false);
        setSubmitting(false);
      })
      .catch(err => {
        setVisible(false);
        setStatusMsg(err.message);
      });
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...defaultCompany,
        tier: 'Client Only',
      }}
      onSubmit={onSubmit}
    >
      { props => 
        <AddExistingClientFormRender
          setVisible={setVisible}
          companyName={companyName}
          companies={companies}
          setSelectedClientIDVal={setSelectedClientIDVal}
          setStatusMsg={setStatusMsg}
          {...props}
        />
      }
    </Formik>
  );
};


export default AddExistingClientForm