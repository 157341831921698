import { useCallback, useMemo } from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';

const COMPANY_GROUP_UPDATE_MUTATION = gql`
  mutation UpdateCompanyGroup($input: CompanyGroupInput!){
    updateCompanyGroup(input: $input) {
      id
      companyID
      name
      role {
        id
        name
      }
      hasAdminRead
      hasAdminWrite
      hasAdminDelete
    }
  }
`;

export const useUpdateCompanyGroup = () => {
  const [updateCompanyGroup] = useMutation(COMPANY_GROUP_UPDATE_MUTATION);
  return useCallback((input) => (
    updateCompanyGroup({
      variables: {
        input,
      },
    })
  ), [updateCompanyGroup]);
}

const COMPANY_GROUP_ADD_MUTATION = gql`
  mutation AddCompanyGroup($input: CompanyGroupInput!){
    addCompanyGroup(input: $input) {
      id
      companyID
      name
      hasAdminRead
      hasAdminWrite
      hasAdminDelete
    }
  }
`;

export const useAddCompanyGroup = () => {
  const [addCompanyGroup] = useMutation(COMPANY_GROUP_ADD_MUTATION);
  return useCallback((input) => (
    addCompanyGroup({
      variables: {
        input,
      },
      refetchQueries: ['AllCompanyGroupsForCompany'],
    })
  ), [addCompanyGroup]);
}

const COMPANY_GROUP_QUERY = gql`
  query CompanyGroup($id: ID!) {
    companyGroup(id: $id) {
      id
      companyID
      name
      role {
        id
        name
      }
      hasAdminRead
      hasAdminWrite
      hasAdminDelete
    }
  }
`;

export const useCompanyGroup = (id) => {
  const { data, loading, error } = useQuery(COMPANY_GROUP_QUERY, {
    variables: {
      id,
    },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyGroup
      : []
  ), [data, loading, error]);
};

const COMPANY_GROUPS_FOR_COMPANY_QUERY = gql`
  query AllCompanyGroupsForCompany($companyID: ID!) {
    companyGroups(companyID: $companyID) {
      id
      name
      hasAdminRead
      hasAdminWrite
      hasAdminDelete
      role {
        name
        roleRank
      }
    }
  }
`;

export const useCompanyGroupsForCompany = (companyID) => {
  const { data, loading, error } = useQuery(COMPANY_GROUPS_FOR_COMPANY_QUERY, {
    variables: { companyID },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyGroups
      : []
  ), [data, loading, error]);
};

const COMPANY_GROUP_PERMISSIONS_QUERY = gql`
  query CompanyGroupPermissions($companyGroupID: ID!) {
    companyGroupPermissions(companyGroupID: $companyGroupID) {
      id
      key
    }
  }
`;

export const useCompanyGroupPermissions = (companyGroupID) => {
  const { data, loading, error } = useQuery(COMPANY_GROUP_PERMISSIONS_QUERY, {
    variables: { companyGroupID },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companyGroupPermissions
      : []
  ), [data, loading, error]);
};

const UPDATE_COMPANY_GROUP_PERMISSIONS = gql`
  mutation UpdateCompanyGroupPermissions($companyGroupID: ID!, $input: PermissionsInput!) {
    updateCompanyGroupPermissions(companyGroupID: $companyGroupID, input: $input) {
      id
      key
    }
  }
`;

export const useUpdateCompanyGroupPermissions = () => {
  const [updateCompanyGroupPermissions] = useMutation(UPDATE_COMPANY_GROUP_PERMISSIONS);
  return useCallback((companyGroupID, input) => (
    updateCompanyGroupPermissions({
      variables: {
        companyGroupID,
        input,
      },
    })
  ), [updateCompanyGroupPermissions]);
}
