import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {
  usePermissionsMap,
} from 'graphql/common';
import {
  PermissionsGroupLabel,
  ReadablePermissions,
  permissionIsEditable,
} from 'utils/permissions';
import styles from './styles.module.scss';
import {
  FieldArray,
} from 'formik';

const PermissionsForm = ({
  values,
  setValues,
  errors,
  availablePermissionGroups,
}) => {
  const permissions = usePermissionsMap();
  const onChange = (arrayHelpers, pID) => (e) => {
    if (e.target.checked) arrayHelpers.push(pID);
    else {
      const idx = values.permissions.indexOf(pID);
      arrayHelpers.remove(idx);
    }
  };

  return (
    <Modal.Body>
      <Form.Group>
        <FieldArray
          name="permissions"
          render={arrayHelpers => (
            Object.keys(availablePermissionGroups).map(group => (
              !!availablePermissionGroups[group] && (
                <div className={styles.permissionsGroup} key={group}>
                  <h5>{PermissionsGroupLabel[group]}</h5>
                  <div className={styles.permissionsList}>
                    {!!permissions && availablePermissionGroups[group].map(p => {
                      return (
                        <Form.Check
                          inline
                          disabled={!permissionIsEditable(p)}
                          type="checkbox"
                          id={permissions[p]}
                          key={p}
                          value={permissions[p]}
                          checked={values.permissions.includes(permissions[p])}
                          onChange={onChange(arrayHelpers, permissions[p])}
                          label={ReadablePermissions[p]}
                        />
                      );
                    })}
                  </div>
                </div>
              )
            ))
          )}
        />
      </Form.Group>
    </Modal.Body>
  );
};

export default PermissionsForm;
