import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  Form as FormikForm,
  Formik,
} from 'formik';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Alert from 'react-bootstrap/Alert';
import {
  useUpdateAccount,
  useAddAccount,
  useUpdateAccountCompanyGroups,
  useUpdateAccountSettings,
  useCompanyGroupsForAccount,
  useAccount,
  useBulkUpdateAccounts,
  useRemoveCompanyAccount,
  useCompanyAccountPermissions,
  useUpdateCompanyAccountPermissions,
} from 'graphql/accounts';
import {
  CompanyAccountPermissions,
} from 'utils/permissions';
import {
  ConfirmCard,
  ChangedPermissions,
  PermissionsForm,
} from 'components/common';
import {
  useCompany,
} from 'graphql/companies';
import { useDefaultAccount } from 'tabs/Accounts/Accounts';
import { phoneRegex } from 'utils/formats';
import { actions } from 'utils/form';
import SMSLogTable from './SMSLogTable';
import AccountSettings from './AccountSettings';
import AccountBasicForm from './AccountBasicForm';
import styles from './styles.module.scss';

const getSubmitText = (action, count) => {
  switch (action) {
    case actions.UPDATE:
      return 'Update';
    case actions.BULKUPDATE:
      return `Bulk Update ${count} account${count > 1 ? 's' : ''}`;
    case actions.ADD:
    default:
      return 'Add';
  }
}

const AccountFormControls = ({
  values,
  isSubmitting,
  handleSubmit,
  submitForm,
  setStatus,
  action,
  setVisible,
  setConfirmVisible,
  confirmVisible,
  setConfirmDeleteVisible,
  confirmDeleteVisible,
  companyName,
  companyID,
  selectedAccounts,
  oldPermissions,
}) => {
  const removeCompanyAccount = useRemoveCompanyAccount();
  const removeCompanyAccountCallback = () => {
    removeCompanyAccount(values.id, companyID)
      .then(() => {
        setVisible(false);
      })
      .catch(e => setStatus({ error: e.message }));
  }
  const submitCallback = () => {
    submitForm()
      .then(() => setVisible(false))
      .catch(e => setStatus({ error: e.message }));
  };
  if (confirmVisible || confirmDeleteVisible) {
    return (
      <>
        {confirmDeleteVisible && (
          <ConfirmCard
            text={`Are you sure you want to remove the account ${values.firstName} ${values.lastName} from ${companyName}?`}
            buttonText="Confirm Removal"
            variant="danger"
            setVisible={setVisible}
            callback={removeCompanyAccountCallback}
          />
        )}
        {action === actions.UPDATE && confirmVisible && (
          <ConfirmCard
            text={`Are you sure you want to update the account ${values.firstName} ${values.lastName}?`}
            buttonText="Confirm Update"
            variant="primary"
            setVisible={setVisible}
            callback={submitCallback}
          >
            <ChangedPermissions
              oldPermissions={oldPermissions}
              newPermissions={values.permissions}
            />
          </ConfirmCard>
        )}
      </>
    );
  }
  return (
    <Modal.Footer>
      <ButtonToolbar>
        <ButtonGroup>
          <Button
            variant="outline-danger"
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          {action === actions.UPDATE && (
            <Button
              variant="danger"
              onClick={() => setConfirmDeleteVisible(true)}
            >
              REMOVE
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => !isSubmitting && (
              action === actions.UPDATE ? setConfirmVisible(true) : handleSubmit()
            )}
          >
            {getSubmitText(action, selectedAccounts.length)}
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Modal.Footer>
  );
};

const AccountDetailsFormRender = ({
  action,
  setVisible,
  values,
  setValues,
  setFieldValue,
  setStatus,
  isSubmitting,
  handleSubmit,
  submitForm,
  errors,
  status,
  oldPermissions,
  companyName,
  companyID,
  selectedAccounts,
}) => {
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  return (
    <>
      <FormikForm>
        <Tabs className={styles.accountDetailsTabs} mountOnEnter={true} defaultActiveKey={action === actions.BULKUPDATE ? 'settings' : 'basics'}>
          <Tab eventKey="basics" title="Basics" disabled={action === actions.BULKUPDATE}>
            <AccountBasicForm
              values={values}
              setValues={setValues}
              companyID={companyID}
              errors={errors}
              action={action}
            />
          </Tab>
          <Tab eventKey="settings" title="Settings" disabled={action === actions.ADD}>
            <AccountSettings
              action={action}
              values={values}
              setValues={setValues}
            />
          </Tab>
          <Tab eventKey="Permissions" title="Permissions" disabled={action === actions.ADD}>
            <PermissionsForm
              values={values}
              setValues={setValues}
              errors={errors}
              availablePermissionGroups={CompanyAccountPermissions}
            />
          </Tab>
          <Tab eventKey="smslogs" title="SMS Logs" disabled={action === actions.ADD || action === actions.BULKUPDATE}>
            <SMSLogTable
              accountID={values.id}
              action={action}
            />
          </Tab>
        </Tabs>
      </FormikForm>
      {!!status && !!status.error && <Alert variant="danger">{status.error}</Alert>}
      <AccountFormControls
        values={values}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        submitForm={submitForm}
        setStatus={setStatus}
        action={action}
        setVisible={setVisible}
        setConfirmVisible={setConfirmVisible}
        confirmVisible={confirmVisible}
        setConfirmDeleteVisible={setConfirmDeleteVisible}
        confirmDeleteVisible={confirmDeleteVisible}
        companyName={companyName}
        companyID={companyID}
        selectedAccounts={selectedAccounts}
        oldPermissions={oldPermissions}
      />
    </>
  );
};

const AccountDetailsSchema = Yup.object().shape({
  firstName: Yup.string().min(1, 'Too short!').max(255, 'Too long!').required('Required'),
  lastName: Yup.string().min(1, 'Too short!').max(255, 'Too long!').required('Required'),
  phoneNumber: Yup.string().matches(phoneRegex, 'Please enter a valid phone number of the form +14160001111').required('Required'),
});

const AccountDetailsForm = ({
  setVisible,
  action,
  companyID,
  selectedAccountID,
  selectedAccounts,
}) => {
  const account = useAccount(selectedAccountID);
  const company = useCompany(companyID);
  const companyName = company && company.name;
  const defaultAccount = useDefaultAccount();

  const updateAccount = useUpdateAccount();
  const addAccount = useAddAccount();
  const updateAccountCompanyGroups = useUpdateAccountCompanyGroups();
  const updateAccountSettings = useUpdateAccountSettings();
  const companyGroupsForAccount = useCompanyGroupsForAccount(account ? account.id : '0', companyID);
  const bulkUpdateAccounts = useBulkUpdateAccounts();
  const updateCompanyAccountPermissions = useUpdateCompanyAccountPermissions();
  const caPermissions = useCompanyAccountPermissions(companyID, selectedAccountID);

  let companyGroups = companyGroupsForAccount.map(cg => cg.id)

  const oldPermissions = caPermissions.map(p => p.id);

  let caValues = { ...defaultAccount, companyGroups }
  if (!!account) {
    caValues = {
      ...caValues,
      ...account,
      companyGroups,
      permissions: oldPermissions,
    };
  }

  const onSubmit = (values, { setSubmitting, setStatus } ) => {
    // Extract __typename because this was set when we initialized values
    // Also take out companyGroups, permissions and accountSettings (these are going to be separate calls)
    const {
      __typename: _,
      companyGroups: companyGroupIDs,
      accountSettings: {
        __typename,
        notificationSettings: {
          __typename: __,
          ...notificationSettings
        },
        ...accountSettings
        },
      permissions,
      ...input
    } = values;
    const accountSettingsInput = { ...accountSettings, notificationSettings };
    const permissionsInput = {
      permissionIDs: permissions,
    };
    setSubmitting(true);
    if (action === actions.UPDATE) {
      updateAccount(input)
        .then(() => (
          updateAccountCompanyGroups(values.id, companyID, companyGroupIDs)
        ))
        .then(() => (
          updateAccountSettings(values.id, accountSettingsInput)
        ))
        .then(() => (
          updateCompanyAccountPermissions(companyID, values.id, permissionsInput)
        ))
        .then(() => {
          setVisible(false);
          setSubmitting(false);
        })
        .catch(err => setStatus({ error: err.message }));
    } else if (action === actions.ADD) {
      const {
        __typename: _,
        id: __,
        companyGroups: companyGroupIDs,
        accountSettings,
        permissions,
        ...input
      } = values;
      addAccount(input, companyID)
      // Need to get returned ID from above mutation
        .then((res) => {
          if (!!res && !!res.data && !!res.data.addAccount && !! res.data.addAccount.id) {
            const newID = res.data.addAccount.id;
            return updateAccountCompanyGroups(newID, companyID, companyGroupIDs);
          }
        })
        .then(() => {
          setVisible(false);
          setSubmitting(false);
        })
        .catch(err => setStatus({ error : err.message }));
    } else if (action === actions.BULKUPDATE) {
      const input = {
        accountSettings: accountSettingsInput,
      };
      bulkUpdateAccounts(selectedAccounts, input);
      setVisible(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={caValues}
      validationSchema={action !== actions.BULKUPDATE && AccountDetailsSchema}
      onSubmit={onSubmit}
      children={props =>
        <AccountDetailsFormRender
          setVisible={setVisible}
          action={action}
          companyName={companyName}
          companyID={companyID}
          selectedAccounts={selectedAccounts}
          oldPermissions={oldPermissions}
          {...props}
        />
      }
    />
  )
};

export default AccountDetailsForm;
