import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { CenterContainer } from 'App';
import { GenericObjectFormModal } from 'components/common';
import {
  useCompanies,
  useCompany,
} from 'graphql/companies';
import { CompanyDetailsForm } from './components'

const CompaniesList = ({
  companies,
  selectedCompanyID,
  setSelectedCompanyID,
  action,
  setAction,
  companyDetailsVisible,
  setCompanyDetailsVisible,
}) => {
  return (
    <>
      <ButtonGroup className="mb-2">
        <Button variant="success" onClick={() => {setAction('add');setCompanyDetailsVisible(true)}}>+ Add Company</Button>
      </ButtonGroup>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Status</th>
            <th>Paid</th>
            <th>Province/State</th>
          </tr>
        </thead>
        <tbody>
          { !!companies &&
              companies.map(c => (
                <CompanyRow key={c.id} company={c} {...{setSelectedCompanyID, setAction, companyDetailsVisible, setCompanyDetailsVisible}}/>
              ))
          }
        </tbody>
      </Table>
    </>
  );
}

const CompanyRow = ({
  company,
  setSelectedCompanyID,
  setAction,
  setCompanyDetailsVisible,
}) => {
  return (
    <tr onClick={() => {setSelectedCompanyID(company.id);setAction('update');setCompanyDetailsVisible(true)}}>
      <td>{company.id}</td>
      <td>{company.name}</td>
      <td className={company.status === 'ACTIVE' ? 'text-success' : 'text-danger'}>{company.status}</td>
      <td className={company.paid_customer ? 'text-success' : 'text-danger'}>{company.paid_customer ? 'PAID' : 'UNPAID'}</td>
      <td>{company.province_state}</td>
    </tr>
  );
}

export const defaultCompany = {
  id: '',
  name: '',
  status: '',
  province_state: '',
  paid_customer: true,
  permissions: [],
}

const Companies = () => {
  const companies = useCompanies();
  const [selectedCompanyID, setSelectedCompanyID] = useState(0);
  const [action, setAction] = useState('update');
  const [companyDetailsVisible, setCompanyDetailsVisible] = useState(false);
  const company = useCompany(selectedCompanyID);

  return (
    <CenterContainer>
      <Card className="my-2">
        <Card.Body>
          <CompaniesList
            companies={companies}
            {...{
              selectedCompanyID,
              setSelectedCompanyID,
              action,
              setAction,
              companyDetailsVisible,
              setCompanyDetailsVisible,
            }}
          />
          <GenericObjectFormModal 
            visible={companyDetailsVisible}
            setVisible={setCompanyDetailsVisible}
            action={action}
            FormComponent={CompanyDetailsForm}
            company={company}
          />
        </Card.Body>
      </Card>
    </CenterContainer>
  );
};

export default Companies;
