import React, { useState } from 'react';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import {
  Formik,
  Form as FormikForm,
} from 'formik';
import {
  useAddCompanyGroup,
  useUpdateCompanyGroup,
  useCompanyGroupPermissions,
  useUpdateCompanyGroupPermissions,
} from 'graphql/company_groups';
import {
  ConfirmCard,
  ChangedPermissions,
} from 'components/common';
import { defaultCompanyGroup } from 'tabs/CompanyGroups/CompanyGroups';
import CompanyGroupBasicForm from './CompanyGroupBasicForm';
import styles from 'components/common/styles.module.scss';

const CompanyGroupDetailsSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Too short!').max(255, 'Too long!').required('Required'),
});

const CompanyGroupDetailsFormRender = ({
  values,
  setValues,
  handleSubmit,
  submitForm,
  errors,
  status,
  setStatus,
  isSubmitting,
  oldPermissions,
  action,
  setVisible,
  ...rest
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const submitCallback = () => {
    submitForm()
      .then(() => setVisible(false))
      .catch(e => setStatus({ error: e.message }));
  };

  return (
    <>
      <FormikForm>
        <Tabs className={styles.formTabs} mountOnEnter={true}>
          <Tab eventKey="basics" title="Basics">
            <CompanyGroupBasicForm values={values} setValues={setValues} errors={errors} />
          </Tab>
        </Tabs>
      </FormikForm>
      {!!status && !!status.error && <Alert variant="danger">{status.error}</Alert>}
      {!confirmVisible && (
        <Modal.Footer>
          <Button variant="danger" onClick={() => setVisible(false)}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => !isSubmitting && (
              action === 'update' ? setConfirmVisible(true) : handleSubmit()
            )}
          >
            {action === 'update' ? `Update` : `Add`}
          </Button>
        </Modal.Footer>
      )}
      {action === 'update' && confirmVisible && (
        <ConfirmCard
          text={`Are you sure you want to update the company group ${values.name}?`}
          buttonText="Confirm Update"
          variant="primary"
          setVisible={setVisible}
          callback={submitCallback}
        >
          <ChangedPermissions
            oldPermissions={oldPermissions}
            newPermissions={values.permissions}
          />
        </ConfirmCard>
      )}
    </>
  );
};

const CompanyGroupDetailsForm = ({
  setVisible,
  action,
  companyGroup,
  companyID,
}) => {
  const updateCompanyGroup = useUpdateCompanyGroup();
  const addCompanyGroup = useAddCompanyGroup();
  const companyGroupID = companyGroup ? companyGroup.id : '-1';
  const cgPermissions = useCompanyGroupPermissions(companyGroupID);
  const updateCompanyGroupPermissions = useUpdateCompanyGroupPermissions();
  const oldPermissions = cgPermissions.map(p => p.id);

  let cgValues = {
    ...defaultCompanyGroup,
    companyID,
    role: null,
  };
  if (!!companyGroup){
    cgValues = {
      ...cgValues,
      ...companyGroup,
      permissions: cgPermissions.map(p => p.id),
    };
  }

  const onSubmit = (values, { setSubmitting, setStatus }) => {
    const { __typename: _, permissions, role, ...input } = values;
    const permissionsInput = {
      permissionIDs: permissions,
    };
    const cgInput = { ...input, roleID: role.id };
    console.log(cgInput);
    setSubmitting(true);
    if (action === 'add'){
      addCompanyGroup(cgInput)
        .then(() => {
          setVisible(false);
          setSubmitting(false);
        })
        .catch(err => setStatus({ error: err.message }));
    } else if (action === 'update') {
      updateCompanyGroup(cgInput)
        .then(() => updateCompanyGroupPermissions(cgValues.id, permissionsInput))
        .then(() => {
          setVisible(false);
          setSubmitting(false);
        })
        .catch(err => setStatus({ error: err.message }));
    }
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...cgValues,
      }}
      validationSchema={CompanyGroupDetailsSchema}
      onSubmit={onSubmit}
      children={props =>
        <CompanyGroupDetailsFormRender
          action={action}
          setVisible={setVisible}
          oldPermissions={oldPermissions}
          {...props}
        />
      }
    />
  );
};

export default CompanyGroupDetailsForm;
