import React, { useState } from 'react';
import CSVReader from 'react-csv-reader';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Table from 'react-bootstrap/Table';
import { useBulkAddSubcontractors } from 'graphql/subcontractors';
import { useCompany, useCompanyByName } from 'graphql/companies';
import { useAccountByPhoneNumber } from 'graphql/accounts';
import { useWriteError } from 'graphql/common';
import { phoneRegex } from 'utils/formats';
import styles from './styles.module.scss';

const NewSubcontractorsTable = ({ input, displayErrors }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Operator Count</th>
          <th>Admin Name</th>
          <th>Admin Phonenumber</th>
          {displayErrors && <th>Error</th>}
        </tr>
      </thead>
      <tbody>
        {input.map(i => (
          <NewSubcontractorRow
            key={`subcontractor-${i.subcontractorName}`}
            subcontractorName={i.subcontractorName}
            operatorCount={i.operatorCount}
            adminName={i.adminName}
            adminPhoneNumber={i.adminPhoneNumber}
            error={i.error}
            displayErrors={displayErrors}
          />
        ))}
      </tbody>
    </Table>
  );
}

const ExistsBadge = ({ type, as }) => <Badge variant="warning">Existing {type} found: {as}</Badge>;

const SubcontractorCell = ({
  text,
  Badge,
}) => (
  <td>
    <div className={styles.subcontractorCell}>
      {!!Badge && Badge}
      {text}
    </div>
  </td>
);

const NewSubcontractorRow = ({
  subcontractorName,
  operatorCount,
  adminName,
  adminPhoneNumber,
  error,
  displayErrors,
}) => {
  const company = useCompanyByName(subcontractorName);
  const account = useAccountByPhoneNumber(adminPhoneNumber);
  return (
    <tr>
      <SubcontractorCell
        text={subcontractorName}
        Badge={company && <ExistsBadge type="company" as={company.name} />}
      />
      <SubcontractorCell text={operatorCount} />
      <SubcontractorCell text={adminName} />
      <SubcontractorCell
        text={account ? account.phoneNumber : adminPhoneNumber}
        Badge={account && <ExistsBadge type="account" as={`${account.firstName} ${account.lastName}`}/>}
      />
      {displayErrors && error.length > 0 && <td>{error.join(', ')}</td>}
    </tr>
  );
}

const SubcontractorErrors = ({ input }) => ( 
  <>
    The following rows had errors:
    <NewSubcontractorsTable input={input} displayErrors />
  </>
);

// TODO When globalsearch is in,
// Check uploaded subcontractor names to see if they might have a company already existing
// and warn
const SubcontractorFileUpload = ({ companyID, visible, setVisible }) => {
  const writeError = useWriteError();
  const [file, setFile] = useState('');
  const [input, setInput] = useState([]);
  const [errors, setErrors] = useState([]);
  const bulkAddSubcontractors = useBulkAddSubcontractors();
  const company = useCompany(companyID);
  const clearInput = () => {
    setErrors([]);
    setFile('');
    setInput([]);
  };

  const onHide = () => {
    clearInput();
    setVisible(false);
  };

  const validateAndSetInput = (file) => {
    let invalid = [];
    const input = file.map(r => {
      const values = {
        subcontractorName: r[0] ? r[0].trim() : '',
        operatorCount: r[1] ? r[1].trim() : '',
        adminName: r[2] ? r[2].trim() : '',
        adminPhoneNumber: r[3] ? r[3].trim() : '',
      };
      const phoneValid = phoneRegex.test(values.adminPhoneNumber);
      const operatorCount = parseInt(values.operatorCount, 10);
      const error = [];
      if (!phoneValid) {
        error.push('Invalid phone number');
      }
      if (isNaN(operatorCount)) {
        error.push('Invalid operator count');
      }
      if (error.length > 0) invalid.push({ ...values, error: error });
      return values;
    });
    setInput(input);

    if (invalid.length > 0) {
      setErrors(invalid);
    }
  };

  const submitBulkAddSubcontractors = () => {
    bulkAddSubcontractors(companyID, input)
      .then(onHide)
      .catch(err => {
        writeError(err);
      });
  };

  return (
    <Modal
      show={visible}
      onHide={onHide}
      size="xl"
    >
      <Modal.Header>
        Upload a CSV file of {company && company.name} subcontractors
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>File should be formatted as:</Form.Label>
            <Alert variant="secondary">
              subcontractorCompanyName, operatorCount, adminName, adminPhonenumber
            </Alert>
            <p>Phone numbers must be in the format: +14160001111</p>
            <CSVReader
              onFileLoaded={(csv, fname) => {
                clearInput();
                setFile(csv);
                // Need to filter since EOF seems to be interpreted as a new row
                validateAndSetInput(csv.filter(r => r.length > 1));
              }}
            />
              {errors.length > 0 && (
                <Alert variant="danger">
                  <SubcontractorErrors input={errors} />
                </Alert>
              )}
          </Form.Group>
          {file && input && errors.length <= 0 && (
            <>
              <Form.Group>
                <Form.Label>Verify the following new subcontractors</Form.Label>
                <Alert variant="warning">
                  Companies WILL BE CREATED regardless whether their name matches an existing company<br />
                  Existing Accounts if found by Phone Number will be added as an Administrator of the associated company!
                </Alert>
                <NewSubcontractorsTable input={input} />
              </Form.Group>
              <ButtonToolbar>
                <Button
                  variant="primary"
                  onClick={submitBulkAddSubcontractors}
                >
                  Submit
                </Button>
                <Button
                  variant="danger"
                  onClick={onHide}
                >
                  Cancel
                </Button>
              </ButtonToolbar>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SubcontractorFileUpload;
